<template>
  <div>
    <pre style="display: none">{{ closeModal }}</pre>
    <pre style="display: none">{{ orders }}</pre>
    <!-- <pre>{{dates}}</pre> -->
    <div v-if="shopList.length === 0" flat>
      <v-row align="center" class="py-10 px-3">
        <v-col class="text-center" lg="6" md="6" sm="12" cols="12">
          <div
            :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
            class="text-start hero-landing"
          >
            <h1 class="hero">
              {{ $t('EOrdering.createShop') }}
            </h1>
            <p class="hero-subtitle">
              <span class="text--primary font-weight-semibold">{{ $t('EOrdering.hero.easiestWay') }}</span>
              <span class="text--primary font-weight-semibold"> {{ $t('EOrdering.hero.moreSales') }}.</span>
              <span> {{ $t('EOrdering.hero.isByAdding') }}</span>
              <span class="text--primary font-weight-semibold"> {{ $t('EOrdering.hero.here') }}.</span>
            </p>
            <div class="justify-center try-btn">
              <v-btn elevation="2" x-large class="button-goLogin" color="primary" @click="openAddNewLink()">
                <span style="color: white">{{ $t('EOrdering.createShop') }}</span>

                <v-icon color="white">
                  {{ icons.mdiChevronRight }}
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col lg="6" md="6" sm="12" cols="12">
          <div class="ml-12">
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_ouwgtmqa.json"
              background="transparent"
              speed="1"
              class="lottie-image lottie-possition mx-auto"
              loop
              autoplay
            ></lottie-player>
          </div>
        </v-col>
      </v-row>

      <!-- login form -->
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" lg="8" md="8" sm="12">
          <div class="shop-header">
            <v-row>
              <v-col cols="9">
                <div class="d-flex">
                  <img
                    class="shop-logo me-4"
                    :src="
                      currentShop && currentShop.shopLogo
                        ? currentShop.shopLogo.uploadURL
                        : 'https://previews.123rf.com/images/prettyvectors/prettyvectors1502/prettyvectors150200051/36241121-shop-vector-illustration.jpg'
                    "
                  />
                  <span
                    :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                    class="text-h4 mt-1 font-weight-black"
                    >{{ currentShop && currentShop.name ? currentShop.name : '' }}</span
                  >
                  <v-menu rounded offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon fab class="mt-2 ms-2" v-on="on">
                        <v-icon :color="$vuetify.theme.isDark === true ? '#fffff' : '#000000'" size="22">
                          {{ icons.mdiChevronDown }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="list-shop">
                      <v-list-item
                        v-for="(shop, indexShop) in shops"
                        :key="`shop-index` + indexShop"
                        link
                        :class="$vuetify.theme.isDark === true ? 'shop-list-dark' : 'shop-list-light'"
                      >
                        <v-list-item-title
                          @click="$store.commit('eOrdering/setCurrentShop', JSON.parse(JSON.stringify(shop)))"
                        >
                          <div class="d-flex text-start">
                            <span class="text-h7 font-weight-medium">{{ shop.name }}</span>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        class="add-shop"
                        :class="$vuetify.theme.isDark === true ? 'add-shop-dark' : 'add-shop-light'"
                        link
                      >
                        <v-list-item-title class="text-start">
                          <div @click="openAddNewLink">
                            <v-icon color="primary">
                              {{ icons.mdiPlusCircle }}
                            </v-icon>
                            <span class="text-h7 font-weight-medium"> Add New Shop</span>
                          </div>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col class="d-flex justify-end" cols="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="me-2"
                      small
                      fab
                      :color="$vuetify.theme.isDark === true ? 'black' : 'white'"
                      v-bind="attrs"
                      v-on="on"
                      @click="editShop"
                    >
                      <v-icon color="success">
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('EOrdering.editShop') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      fab
                      :color="$vuetify.theme.isDark === true ? 'black' : 'white'"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteShop"
                    >
                      <v-icon color="error">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('EOrdering.deleteShop') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
          <div class="text-subtitle-1 shop-subtitle font-weight-normal">
            <span :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
              >Monitor your store activity to maintain buyer satisfaction</span
            >
          </div>

          <v-layout row wrap>
            <v-flex v-for="(d, indexStatistic) in 4" :key="indexStatistic + 'id'" md3 class="mt-8">
              <v-card elevation="4" class="border-card-statistic mx-2" @click="goToOrder(d)">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <div v-if="indexStatistic === 0">
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h7 font-weight-normal text-statistic-title"
                          >New Order</span
                        >
                        <br />
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h4 font-weight-black text-statistic"
                          >{{ itemStatistic.pending }}</span
                        >
                      </div>
                      <div v-if="indexStatistic === 1">
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h7 font-weight-normal text-statistic-title"
                          >Preparing Order</span
                        >
                        <br />
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h4 font-weight-black text-statistic"
                          >{{ itemStatistic.onProgress }}</span
                        >
                      </div>
                      <div v-if="indexStatistic === 2">
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h7 font-weight-normal text-statistic-title"
                          >Completed</span
                        >
                        <br />
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h4 font-weight-black text-statistic"
                          >{{ itemStatistic.completed }}</span
                        >
                      </div>
                      <div v-if="indexStatistic === 3">
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h7 font-weight-normal text-statistic-title"
                          >Canceled</span
                        >
                        <br />
                        <span
                          :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                          class="text-h4 font-weight-black text-statistic"
                          >{{ itemStatistic.canceled }}</span
                        >
                      </div>
                    </v-col>
                    <div class="flex text-end">
                      <span
                        :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                        class="text-detail font-weight-normal"
                        >Detail</span
                      >
                      <!-- <span class="text-detail-2 font-weight-black">>></span> -->
                      <v-icon size="14" :color="$vuetify.theme.isDark === true ? '#ffffff' : '#000000'" class="ms-1">
                        {{ icons.mdiChevronDoubleRight }}
                      </v-icon>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <br />
          <v-card class="border-card-statistic">
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="4" md="4" sm="12">
                  <div>
                    <span
                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      class="font-weight-black text-h6"
                      >Store Statistic</span
                    >
                  </div>
                  <span
                    :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                    class="font-weight-medium text-subtitle-2"
                    >See more details</span
                  >
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="$t('broadcasts.date')"
                        :prepend-icon="icons.mdiCalendarMonth"
                        :value="dates.join(' - ')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="dates" no-title scrollable range @change="loadOrderDataChange2">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="8" md="8" sm="12">
                  <v-row>
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-card elevation="4" class="border-card-statistic">
                        <v-card-text class="text-center">
                          <span
                            :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                            class="text-h7 font-weight-normal text-statistic-title"
                            >New Income</span
                          >
                          <br />
                          <span
                            :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                            class="text-h6 font-weight-black text-statistic-revenue"
                            >{{ currency(itemStatistic.weeklyRevenue, 'IDR', 'en') }}</span
                          >
                          <div>
                            <span
                              :style="weeklyRevenue.status === '+' ? 'color: #47DD00;' : 'color: red;'"
                              class="text-subtitle-2 font-weight-normal"
                              >{{ weeklyRevenue.status }}{{ weeklyRevenue.total }}
                            </span>
                            <span
                              :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                              class="text-subtitle-2 font-weight-normal"
                              >dari 7 hari lalu</span
                            >
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12">
                      <v-card elevation="4" class="border-card-statistic">
                        <v-card-text class="text-center">
                          <span
                            :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                            class="text-h7 font-weight-normal text-statistic-title"
                            >Incoming Order</span
                          >
                          <br />
                          <span
                            :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                            class="text-h6 font-weight-black text-statistic-revenue"
                            >{{ itemStatistic.totalOrder }}</span
                          >
                          <div>
                            <span
                              :style="weeklyOrder.status === '+' ? 'color: #47DD00;' : 'color: red;'"
                              class="text-subtitle-2 font-weight-normal"
                              >{{ weeklyOrder.status }}{{ weeklyOrder.total }}
                            </span>
                            <span
                              :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                              class="text-subtitle-2 font-weight-normal"
                              >dari 7 hari lalu</span
                            >
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card>
              <v-card-text>
                <div class="store-statistic">
                  <span
                    :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                    class="text-h5 font-weight-black text-statistic-revenue"
                    >Income</span
                  >
                  <vue-apex-charts
                    type="area"
                    height="300"
                    :options="chartOptionsRevenue"
                    :series="chartSeriesRevenue"
                  ></vue-apex-charts>
                  <span
                    :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                    class="text-h5 font-weight-black text-statistic-revenue"
                    >Order</span
                  >
                  <vue-apex-charts
                    type="area"
                    height="300"
                    :options="chartOptionsOrder"
                    :series="chartSeriesOrder"
                  ></vue-apex-charts>
                </div>
              </v-card-text>
            </v-card>
          </v-card>
          <!-- <v-tabs v-model="currentTab">
            <v-tab
              v-for="(d, i) in shopList"
              :key="i + '-e-ordering'"
              @change="loadOrderDataChange(shopList[i]), resetOrderSelect()"
            >
              {{ d.data.name }}
            </v-tab>
          </v-tabs> -->
          <!-- <v-tabs-items v-model="currentTab">
            <v-tab-item
              v-for="(item, i) in shopList"
              :key="i + '-order'"
            >
              <br />
              <v-row justify="end">
                <v-col
                  class="my-auto"
                  cols="auto"
                >
                  <v-tooltip
                    v-if="statusChannelsBanner && bannerAlertText"
                    bottom
                    color="warning"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="warning"
                        size="32"
                        v-on="on"
                      >
                        {{ icons.mdiAlert }}
                      </v-icon>
                    </template>
                    <span>{{ bannerAlertText }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        fab
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteShop(item)"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('EOrdering.deleteShop') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="auto"
                  md="auto"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        fab
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="editShop(item)"
                      >
                        <v-icon>{{ icons.mdiPencil }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('EOrdering.editShop') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="auto"
                >
                  <v-btn
                    block
                    color="primary"
                    @click="createSlug(item)"
                  >
                    {{ $t('EOrdering.addLink') }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="auto"
                >
                  <v-btn
                    block
                    color="primary"
                    @click="editSheet(item)"
                  >
                    <v-icon left>
                      {{ icons.mdiCog }}
                    </v-icon>
                    {{ $t('EOrdering.product') }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="auto"
                >
                  <v-btn
                    block
                    color="primary"
                    @click="goToContacts(item.data.selectedContactList)"
                  >
                    {{ $t('EOrdering.viewContact') }}
                  </v-btn>
                </v-col>
              </v-row>
              <br />
              <div class="d-flex">
                <h2>Dashboard</h2>

                <v-switch
                  v-model="detailShop"
                  class="ms-3 my-0"
                  :label="`show Detail`"
                ></v-switch>
              </div>
              <br />

              <v-row no-gutters>
                <v-col
                  v-if="detailShop"
                  cols="12"
                >
                  <v-card class="border-card">
                    <v-card-text>
                      <v-row class="my-auto">
                        <v-col class="flex-grow-1">
                          <v-row>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <h5>{{ $t('EOrdering.shopName') }}</h5>
                            </v-col>
                            <v-col>
                              <h3>: {{ item.data.name }}</h3>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <h5>{{ $t('EOrdering.generatedLink') }}</h5>
                            </v-col>
                            <v-col>
                              <h3 v-if="item.data.urlGenerate.length === 0">
                                : {{ $t('EOrdering.noLink') }}
                              </h3>
                              <h3 v-if="item.data.urlGenerate.length !== 0">
                                :
                                <span
                                  v-for="(url, indexUrl) in item.data.urlGenerate"
                                  :key="indexUrl + 'url'"
                                >
                                  <a
                                    v-if="url.slug"
                                    target="_blank"
                                    :href="`${business_plus_url}/order/${url.slug}`"
                                  >{{
                                    url.slug
                                  }}</a>
                                  <v-btn
                                    v-if="url.slug"
                                    icon
                                  >
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-if="url.slug"
                                          v-bind="attrs"
                                          icon
                                          v-on="on"
                                          @click="copyURL(url.slug)"
                                        >
                                          <v-icon>
                                            {{ icons.mdiContentCopy }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('EOrdering.copyLink') }}</span>
                                    </v-tooltip>
                                  </v-btn>

                                  <v-btn
                                    v-if="url.slug"
                                    icon
                                  >
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-if="url.slug"
                                          v-bind="attrs"
                                          icon
                                          v-on="on"
                                          @click="editSlug(item, url)"
                                        >
                                          <v-icon>
                                            {{ icons.mdiPencil }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('EOrdering.editLink') }}</span>
                                    </v-tooltip>
                                  </v-btn>

                                  <v-btn
                                    v-if="url.slug"
                                    icon
                                  >
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-if="url.slug"
                                          v-bind="attrs"
                                          icon
                                          v-on="on"
                                          @click="deleteSlug(item)"
                                        >
                                          <v-icon>
                                            {{ icons.mdiDeleteOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('EOrdering.deleteLink') }}</span>
                                    </v-tooltip>
                                  </v-btn>

                                  <v-btn
                                    v-if="url.slug"
                                    icon
                                  >
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-if="url.slug"
                                          v-bind="attrs"
                                          icon
                                          v-on="on"
                                          @click="LinkBroadCast(`${business_plus_url}/order/${url.slug}`)"
                                        >
                                          <v-icon>
                                            {{ icons.mdiBroadcast }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('EOrdering.broadcastLink') }}</span>
                                    </v-tooltip>
                                  </v-btn>
                                </span>
                              </h3>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <h5>{{ $t('EOrdering.driveLink') }}</h5>
                            </v-col>
                            <v-col>
                              <h3>
                                : <a
                                  target="_blank"
                                  :href="item.data.driveInfo.link"
                                >{{ $t('EOrdering.toDrive') }}</a>
                                <v-btn
                                  v-if="item.data.driveInfo.link"
                                  icon
                                  @click="copyURL(item.data.driveInfo.link)"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-if="item.data.driveInfo.link"
                                        v-bind="attrs"
                                        icon
                                        v-on="on"
                                      >
                                        <v-icon>
                                          {{ icons.mdiContentCopy }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('EOrdering.copyLink') }}</span>
                                  </v-tooltip>
                                </v-btn>

                                <v-btn
                                  v-if="item.data.driveInfo.link"
                                  icon
                                  @click="updateProduct(item)"
                                >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-if="item.data.driveInfo.link"
                                        v-bind="attrs"
                                        icon
                                        v-on="on"
                                      >
                                        <v-icon>
                                          {{ icons.mdiSync }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('EOrdering.updateProduct') }}</span>
                                  </v-tooltip>
                                </v-btn>
                                <v-progress-circular
                                  v-if="loadingTabs"
                                  indeterminate
                                  class="ms-1"
                                  :size="20"
                                  color="primary"
                                ></v-progress-circular>
                              </h3>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              md="2"
                            >
                              <h5>{{ $t('EOrdering.address') }}</h5>
                            </v-col>
                            <v-col>
                              <h3>
                                :
                                {{
                                  `${item.data.address.detail}${
                                    item.data.address.city.type ? ', ' + item.data.address.city.type + ' ' : ''
                                  } ${
                                    item.data.address.city.city_name ? item.data.address.city.city_name + ',' : ''
                                  } ${
                                    item.data.address.subdistrict.subdistrict_name
                                      ? 'Kecamatan '+ item.data.address.subdistrict.subdistrict_name + ','
                                      : ''
                                  } ${item.data.address.city.province ? item.data.address.city.province : ''}`
                                }}
                              </h3>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-layout
                    row
                    wrap
                    class="mx-3"
                  >
                    <v-flex
                      v-for="(d, indexStatistic) in 5"
                      :key="indexStatistic + 'id'"
                      md4
                      class="lg5-custom"
                    >
                      <v-card flat>
                        <v-card-text>
                          <v-card class="border-card">
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-btn
                                    v-if="indexStatistic === 0"
                                    color="primary"
                                    small
                                    fab
                                  >
                                    <v-icon>
                                      {{ icons.mdiCurrencyUsd }}
                                    </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-else-if="indexStatistic === 1"
                                    color="info"
                                    small
                                    fab
                                  >
                                    <v-icon>
                                      {{ icons.mdiPackageDown }}
                                    </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-else-if="indexStatistic === 2"
                                    color="warning"
                                    small
                                    fab
                                  >
                                    <v-icon>
                                      {{ icons.mdiPackageDown }}
                                    </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-else-if="indexStatistic === 3"
                                    color="success"
                                    small
                                    fab
                                  >
                                    <v-icon>
                                      {{ icons.mdiPackageUp }}
                                    </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-else-if="indexStatistic === 4"
                                    color="error"
                                    small
                                    fab
                                  >
                                    <v-icon>
                                      {{ icons.mdiCancel }}
                                    </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-else
                                    color="primary"
                                    small
                                    fab
                                  >
                                    <v-icon>
                                      {{ icons.mdiStore }}
                                    </v-icon>
                                  </v-btn>
                                </v-col>
                                <v-col v-if="indexStatistic === 0">
                                  <h5>
                                    {{ currency(itemStatistic.Total, 'IDR', 'en') }}
                                  </h5>
                                  <h5>{{ $t('EOrdering.totalRevenue') }}</h5>
                                </v-col>
                                <v-col v-else-if="indexStatistic === 1">
                                  <h5>
                                    {{ itemStatistic.Quantity }}
                                  </h5>
                                  <h5>{{ $t('EOrdering.totalIncoming') }}</h5>
                                </v-col>
                                <v-col v-else-if="indexStatistic === 2">
                                  <h5>{{ itemStatistic.Status }}</h5>
                                  <h5>{{ $t('EOrdering.totalPending') }}</h5>
                                </v-col>
                                <v-col v-else-if="indexStatistic === 3">
                                  <h5> {{ itemStatistic.Done }}</h5>
                                  <h5>{{ $t('EOrdering.completedOrder') }}</h5>
                                </v-col>
                                <v-col v-else>
                                  <h5>
                                    {{ itemStatistic.Cancel }}
                                  </h5>
                                  <h5>{{ $t('EOrdering.canceledOrder') }}</h5>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    v-model="menu[`menu${i}`]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :label="$t('broadcasts.date')"
                        :prepend-icon="icons.mdiCalendarMonth"
                        :value="dates[i].join(' - ')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dates[i]"
                      no-title
                      scrollable
                      range
                      @change="loadOrderDataChange(shopList[i])"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    :disabled="selectedTableData.length === 0"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <div class="pt-3">
                          <v-btn
                            block
                            color="primary"
                            :disabled="selectedTableData.length === 0"
                          >
                            {{ $t('EOrdering.bulkUpdateStatus') }}
                          </v-btn>
                        </div>
                      </div>
                    </template>
                    <div class="mx-auto my-auto">
                      <v-list dense>
                        <v-list-item-group
                          v-model="chips"
                          color="primary"
                          class="mx-auto"
                        >
                          <v-list-item
                            v-for="(stat, index) in status"
                            :key="index"
                            @click="bulkUpdateStatus"
                          >
                            <v-chip
                              v-if="stat.value === 0"
                              color="warning"
                              @click="selectedStatus = 0"
                            >
                              {{ $t('EOrdering.pending') }}
                            </v-chip>
                            <v-chip
                              v-if="stat.value === 1"
                              color="info"
                              @click="selectedStatus = 1"
                            >
                              {{ $t('EOrdering.onProgress') }}
                            </v-chip>
                            <v-chip
                              v-if="stat.value === 2"
                              color="primary"
                              @click="selectedStatus = 2"
                            >
                              {{ $t('EOrdering.paid') }}
                            </v-chip>
                            <v-chip
                              v-if="stat.value === 3"
                              color="success"
                              @click="selectedStatus = 3"
                            >
                              {{ $t('EOrdering.done') }}
                            </v-chip>
                            <v-chip
                              v-if="stat.value === 4"
                              color="error"
                              @click="selectedStatus = 4"
                            >
                              {{ $t('EOrdering.cancel') }}
                            </v-chip>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </div>
                  </v-menu>
                </v-col>
              </v-row>

              <v-tabs v-model="currentTab2">
                <v-tab>{{ $t('EOrdering.transaction') }}</v-tab>
              </v-tabs>


              <v-tabs-items
                v-model="currentTab2"
              >
                <v-tab-item>
                  <v-data-table
                    v-model="selectedTableData"
                    :headers="headers"
                    item-key="_id"
                    :items="orders"
                    :items-per-page="10"
                    :sort-desc="[true]"
                    multi-sort
                    show-select
                    class="text-no-wrap text-center"
                    :footer-props="{
                      'items-per-page-text': $t('rowsPerPageLabel'),
                    }"
                  >
                    <template #[`item.option`]="{ item }">
                      <v-btn
                        fab
                        small
                        icon
                        @click=";(openDetailTable = true), (detailData = item)"
                      >
                        <v-icon>{{ icons.mdiTextBox }}</v-icon>
                      </v-btn>
                    </template>
                    <template #[`item.status`]="{ item }">
                      <v-menu>
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <div class="d-inline-flex flex-column justify-center ms-3">
                              <v-chip
                                v-if="item.status === 0"
                                color="warning"
                              >
                                {{ $t('EOrdering.pending') }}
                              </v-chip>
                              <v-chip
                                v-if="item.status === 1"
                                color="info"
                              >
                                {{ $t('EOrdering.onProgress') }}
                              </v-chip>
                              <v-chip
                                v-if="item.status === 2"
                                color="primary"
                              >
                                {{ $t('EOrdering.paid') }}
                              </v-chip>
                              <v-chip
                                v-if="item.status === 3"
                                color="success"
                              >
                                {{ $t('EOrdering.done') }}
                              </v-chip>
                              <v-chip
                                v-if="item.status === 4"
                                color="error"
                              >
                                {{ $t('EOrdering.cancel') }}
                              </v-chip>
                            </div>
                            <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                          </div>
                        </template>
                        <div class="mx-auto my-auto">
                          <v-list dense>
                            <v-list-item-group
                              v-model="chips"
                              color="primary"
                              class="mb-2 mx-auto"
                            >
                              <v-list-item
                                v-for="(stat, index) in status"
                                :key="index"
                                @click="openDialogEditStatus(item)"
                              >
                                <v-chip
                                  v-if="stat.value === 0"
                                  color="warning"
                                  @click="selectedStatus = 0"
                                >
                                  {{ $t('EOrdering.pending') }}
                                </v-chip>
                                <v-chip
                                  v-if="stat.value === 1"
                                  color="info"
                                  @click="selectedStatus = 1"
                                >
                                  {{ $t('EOrdering.onProgress') }}
                                </v-chip>
                                <v-chip
                                  v-if="stat.value === 2"
                                  color="primary"
                                  @click="selectedStatus = 2"
                                >
                                  {{ $t('EOrdering.paid') }}
                                </v-chip>
                                <v-chip
                                  v-if="stat.value === 3"
                                  color="success"
                                  @click="selectedStatus = 3"
                                >
                                  {{ $t('EOrdering.done') }}
                                </v-chip>
                                <v-chip
                                  v-if="stat.value === 4"
                                  color="error"
                                  @click="selectedStatus = 4"
                                >
                                  {{ $t('EOrdering.cancel') }}
                                </v-chip>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-menu>
                    </template>
                    <template #[`item.timestamp`]="{ item }">
                      {{ formatTime(item.timestamp) }}
                    </template>
                    <template #[`item.orderData`]="{ item }">
                      {{
                        item.orderData.find(el => el.hasOwnProperty('Order Type'))
                          ? item.orderData.find(el => el['Order Type'])['Order Type']
                          : ''
                      }}
                    </template>
                    <template #[`item.shop_id`]="{ item }">
                      {{ item._id }}
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
          </v-tabs-items> -->
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12" :class="$vuetify.breakpoint.xs ? '' : 'border-divider'">
          <v-card elevation="4" class="border-card-statistic">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <div>
                    <span
                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      class="text-h6 font-weight-black text-statistic-title"
                      >Total Income</span
                    >
                    <br />
                    <span
                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      class="text-h4 font-weight-normal text-statistic"
                      >{{ currency(totalShopRevenue, 'IDR', 'en') }}</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="border-card-statistic mt-4" elevation="4">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between">
                    <span
                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      class="text-h6 font-weight-black text-statistic-title"
                      >Shop Detail</span
                    >
                    <div>
                      <v-btn icon @click="showDetail = !showDetail">
                        <v-icon
                          size="12"
                          :color="$vuetify.theme.isDark === true ? '#ffffff' : '#000000'"
                          class="toggleUpDown"
                          :class="{ rotate: showDetail }"
                        >
                          {{ icons.mdiTriangle }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div v-if="showDetail">
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="4">
                            <span
                              :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                              class="text-subtitle-1 font-weight-normal text-statistic-title"
                              >Address</span
                            >
                          </v-col>
                          <v-col cols="1">
                            <span
                              :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                              class="text-subtitle-1 font-weight-normal text-statistic-title"
                              >:</span
                            >
                          </v-col>
                          <v-col cols="7">
                            <span
                              :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                              class="text-subtitle-1 font-weight-normal text-statistic-title"
                              >{{ formatAddress(JSON.parse(JSON.stringify(currentShop))) }}</span
                            >
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12">
                        <v-row>
                          <v-col cols="4">
                            <span
                              :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                              class="text-subtitle-1 font-weight-normal text-statistic-title"
                              >Link</span
                            >
                          </v-col>
                          <v-col cols="1">
                            <span
                              :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                              class="text-subtitle-1 font-weight-normal text-statistic-title"
                              >:</span
                            >
                          </v-col>
                          <v-col cols="7">
                            <div class="d-flex">
                              <v-row>
                                <v-col cols="12">
                                  <!-- <a
                                    target="_blank"
                                    :href="currentShop.driveInfo.link"
                                  >{{ $t('EOrdering.toDrive') }}</a> -->
                                  <div v-for="(url, indexUrl) in currentShop.urlGenerate" :key="`indexUrl ${indexUrl}`">
                                    <a
                                      v-if="url.slug"
                                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                      class="text-subtitle-1 font-weight-normal text-statistic-title"
                                      target="_blank"
                                      :href="`${business_plus_url}/${url.slug}`"
                                      >{{ url.slug }}</a
                                    >
                                    <!-- <span
                                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                      class="text-subtitle-1 font-weight-normal text-statistic-title"
                                    >{{ url.slug }}</span> -->
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          fab
                                          icon
                                          class="me-n1"
                                          v-on="on"
                                          @click="copyURL(url.slug)"
                                        >
                                          <v-icon :color="$vuetify.theme.isDark === true ? '#ffffff' : '#000000'">
                                            {{ icons.mdiContentCopy }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('EOrdering.copyLink') }}</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          fab
                                          icon
                                          class="me-n1"
                                          v-on="on"
                                          @click="editSlug(JSON.parse(JSON.stringify(currentShop)), url)"
                                        >
                                          <v-icon :color="$vuetify.theme.isDark === true ? '#ffffff' : '#000000'">
                                            {{ icons.mdiPencil }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('EOrdering.editLink') }}</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          fab
                                          icon
                                          class="me-n1"
                                          v-on="on"
                                          @click="LinkBroadCast(`${business_plus_url}/${url.slug}`)"
                                        >
                                          <v-icon :color="$vuetify.theme.isDark === true ? '#ffffff' : '#000000'">
                                            {{ icons.mdiShareVariant }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('EOrdering.broadcastLink') }}</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="statusChannelsBanner && bannerAlertText" bottom color="warning">
                                      <template #activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" color="warning" size="28" v-on="on">
                                          {{ icons.mdiAlert }}
                                        </v-icon>
                                      </template>
                                      <span>{{ bannerAlertText }}</span>
                                    </v-tooltip>

                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          fab
                                          icon
                                          v-bind="attrs"
                                          class="me-n1"
                                          v-on="on"
                                          @click="deleteSlug(JSON.parse(JSON.stringify(currentShop)), url)"
                                        >
                                          <v-icon :color="$vuetify.theme.isDark === true ? '#ffffff' : '#000000'">
                                            {{ icons.mdiDeleteOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ $t('EOrdering.deleteLink') }}</span>
                                    </v-tooltip>
                                  </div>
                                </v-col>
                                <v-col cols="12">
                                  <div class="text-end mt-n6">
                                    <v-btn small @click="createSlug(JSON.parse(JSON.stringify(currentShop)))">
                                      <v-icon class="me-2" color="primary">
                                        {{ icons.mdiPlusCircleOutline }}
                                      </v-icon>
                                      <span class="primary--text text-h7 font-weight-medium">Add New Link</span>
                                    </v-btn>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>

                          <v-col cols="12">
                            <v-row>
                              <v-col cols="4">
                                <span
                                  :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                  class="text-subtitle-1 font-weight-normal text-statistic-title"
                                  >Contact List</span
                                >
                              </v-col>
                              <v-col cols="1">
                                <span
                                  :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                  class="text-subtitle-1 font-weight-normal text-statistic-title"
                                  >:</span
                                >
                              </v-col>
                              <v-col cols="7">
                                <v-row>
                                  <v-col cols="12">
                                    <span
                                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                                      class="text-subtitle-1 font-weight-normal text-statistic-title"
                                      >{{ currentShop.selectedContactList.list_name }}</span
                                    >
                                  </v-col>
                                  <v-col cols="12">
                                    <div class="text-end mt-n6">
                                      <v-btn
                                        small
                                        @click="
                                          goToContacts(JSON.parse(JSON.stringify(currentShop.selectedContactList)))
                                        "
                                      >
                                        <v-icon class="me-2" color="primary">
                                          {{ icons.mdiOpenInNew }}
                                        </v-icon>
                                        <span class="primary--text text-h7 font-weight-medium">See Contact</span>
                                      </v-btn>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn large rounded color="primary" @click="editSheet"> Your Product </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- <v-card
            class="border-card-statistic mt-4"
            elevation="4"
          >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                >
                  <div class="d-flex justify-space-between">
                    <span
                      :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'"
                      class="text-h6 font-weight-black text-statistic-title"
                    >Chat Bot</span>
                    <v-switch class="mt-0"></v-switch>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <v-btn
                    large
                    rounded
                    color="primary"
                  >
                    Settings
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card> -->
        </v-col>
      </v-row>
    </div>

    <!-- Dialog Edit Slug -->
    <v-dialog v-model="openEditSlug" width="600px">
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">{{ $t('edit') }} Link</div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <div class="height-dialog">
            <v-form lazy-validation>
              <v-card-title class="align-start">
                <span class="text-lg font-weight-semibold me-3">Facebook Pixel</span>
                <v-icon>
                  {{ icons.mdiRadioTower }}
                </v-icon>
              </v-card-title>
              <v-card-text>
                <v-card>
                  <v-text-field
                    v-model="slugPayloadEdit.facebookPixelId"
                    label="Pixel ID"
                    :prepend-inner-icon="icons.mdiCounter"
                    outlined
                    placeholder="1236776XXX"
                    hide-details="auto"
                    class="mb-3"
                    dense
                  ></v-text-field>
                </v-card>
              </v-card-text>
              <v-card-title class="align-start">
                <span class="text-lg font-weight-semibold me-3">Google Tag Manager</span>
                <v-icon>
                  {{ icons.mdiImageFilterCenterFocusWeak }}
                </v-icon>
              </v-card-title>
              <v-card-text>
                <v-card>
                  <v-text-field
                    v-model="slugPayloadEdit.googleId"
                    label="Tag Manager ID"
                    :prepend-inner-icon="icons.mdiPoundBoxOutline"
                    outlined
                    placeholder="GTM-123XXX"
                    hide-details="auto"
                    class="mb-3"
                    dense
                  ></v-text-field>
                </v-card>
              </v-card-text>
            </v-form>
          </div>

          <!-- </v-card-text> -->
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="#ffeeee" @click="closeDialogEdit">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveEditSlug">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
          <!-- </v-form>
          </v-card-text> -->
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Dialog Chatbot Setting -->
    <v-dialog v-model="dialogChatbotSetting" width="80%">
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">
            {{ $t('EOrdering.chatbotSetting') }}
          </div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <div class="height-dialog">
            <v-card-title class="align-start">
              <div class="d-flex">
                <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.keyword.msg') }}</span>

                <v-icon style="margin-top: -5px">
                  {{ icons.mdiKeyWireless }}
                </v-icon>
                <v-switch
                  v-model="chatbotSetting.keywordActive"
                  style="margin-top: 0; margin-left: 15px; margin-bottom: -5px"
                  dense
                  :label="chatbotSetting.keywordActive ? 'Use Keyword' : `Don't Use Keyword`"
                ></v-switch>
              </div>
            </v-card-title>
            <v-card-text>
              <v-form lazy-validation>
                <v-card>
                  <v-text-field
                    v-model="chatbotSetting.keyword"
                    required
                    :label="$t('EOrdering.keyword.detail')"
                    :prepend-inner-icon="icons.mdiKeyboardVariant"
                    outlined
                    hide-details="auto"
                    class="mb-3"
                    dense
                  ></v-text-field>
                </v-card>
              </v-form>
            </v-card-text>
            <v-card-title class="align-start">
              <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.greeting.msg') }}</span>
              <v-icon>
                {{ icons.mdiMessage }}
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-form lazy-validation>
                <v-card>
                  <v-text-field
                    v-model="chatbotSetting.greetingMsg"
                    required
                    :label="$t('EOrdering.greeting.detail')"
                    :prepend-inner-icon="icons.mdiMessageArrowRight"
                    outlined
                    hide-details="auto"
                    class="mb-3"
                    dense
                  ></v-text-field>
                </v-card>
              </v-form>
            </v-card-text>

            <v-card-title class="align-start">
              <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.talktoCS.msg') }}</span>
              <v-icon>
                {{ icons.mdiAccount }}
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-text-field
                  v-model="chatbotSetting.talkToCustMsg"
                  :label="$t('EOrdering.talktoCS.detail')"
                  :prepend-inner-icon="icons.mdiHeadset"
                  outlined
                  :placeholder="$t('EOrdering.talktoCS.detail')"
                  hide-details="auto"
                  class="mb-3"
                  dense
                ></v-text-field>
              </v-card>
            </v-card-text>
            <v-card-title class="align-start">
              <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.choosenShop.msg') }}</span>
              <v-icon>
                {{ icons.mdiShopping }}
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-select
                  v-model="chatbotSetting.selectedShop"
                  :label="$t('EOrdering.choosenShop.detail')"
                  :prepend-inner-icon="icons.mdiShoppingSearch"
                  outlined
                  item-text="name"
                  item-value="id"
                  :items="shops"
                  placeholder="Choose E-Shop"
                  hide-details="auto"
                  class="mb-3"
                  dense
                  attach
                ></v-select>
              </v-card>
            </v-card-text>

            <v-card-title class="align-start">
              <span class="text-lg font-weight-semibold me-3">{{ $t('channels.channel') }}</span>
              <v-icon>
                {{ icons.mdiAccountMultiple }}
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-select
                  v-model="chatbotSetting.instanceId"
                  :items="instances"
                  item-text="label"
                  item-value="_id"
                  :prepend-inner-icon="icons.mdiCardAccountDetails"
                  outlined
                  :label="$t('channels.channel')"
                  hide-details
                  required
                  class="mb-3"
                  dense
                  attach
                ></v-select>
              </v-card>
            </v-card-text>

            <v-card-title class="align-start">
              <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.qna.msg') }}</span>
              <v-icon>
                {{ icons.mdiAccountQuestion }}
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-form lazy-validation>
                <v-card>
                  <v-textarea
                    v-model="chatbotSetting.qnaMsg"
                    required
                    :label="$t('EOrdering.qna.detail')"
                    :prepend-inner-icon="icons.mdiChatQuestion"
                    outlined
                    hide-details="auto"
                    class="mb-3"
                    dense
                  ></v-textarea>
                </v-card>
              </v-form>
            </v-card-text>
          </div>

          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" @click="closeChatbotSetting">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveChatbotSetting">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
          <!-- </v-form>
          </v-card-text> -->
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Dialog Create Slug -->
    <v-dialog v-model="openCreateSlug" width="600px">
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">{{ $t('add') }} Link</div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <div>
            <v-card-title class="align-start">
              <span class="text-lg font-weight-semibold me-3">Link</span>
              <v-icon>
                {{ icons.mdiLink }}
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-form lazy-validation>
                <v-card>
                  <v-text-field
                    v-model="input"
                    required
                    label="Slug"
                    :prepend-inner-icon="icons.mdiLinkBoxOutline"
                    outlined
                    hide-details="auto"
                    class="mb-3"
                    dense
                    :rules="[characterValidation.checkCharacter]"
                  ></v-text-field>
                  <div v-if="slugAvailability && input !== ''" class="ml-3 mb-2">
                    <span>{{ $t('LinkGenerator.slugIsAvailability') }} </span>
                    <v-icon color="primary">
                      {{ icons.mdiCheckCircle }}
                    </v-icon>
                  </div>
                  <div v-if="!slugAvailability && input !== ''" class="ml-3 mb-2">
                    <span>{{ $t('LinkGenerator.slugIsNotAvailability') }} </span>
                    <v-icon color="error">
                      {{ icons.mdiCloseCircle }}
                    </v-icon>
                  </div>
                </v-card>
              </v-form>
            </v-card-text>

            <v-card-title class="align-start">
              <span class="text-lg font-weight-semibold me-3">Facebook Pixel</span>
              <v-icon>
                {{ icons.mdiRadioTower }}
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-text-field
                  v-model="slugPayloadEdit.facebookPixelId"
                  label="Pixel ID"
                  :prepend-inner-icon="icons.mdiCounter"
                  outlined
                  placeholder="1236776XXX"
                  hide-details="auto"
                  class="mb-3"
                  dense
                ></v-text-field>
              </v-card>
            </v-card-text>
            <v-card-title class="align-start">
              <span class="text-lg font-weight-semibold me-3">Google Tag Manager</span>
              <v-icon>
                {{ icons.mdiImageFilterCenterFocusWeak }}
              </v-icon>
            </v-card-title>
            <v-card-text>
              <v-card>
                <v-text-field
                  v-model="slugPayloadEdit.googleId"
                  label="Tag Manager ID"
                  :prepend-inner-icon="icons.mdiPoundBoxOutline"
                  outlined
                  placeholder="GTM-123XXX"
                  hide-details="auto"
                  class="mb-3"
                  dense
                ></v-text-field>
              </v-card>
            </v-card-text>
          </div>

          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" @click="closeDialog">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveSlug">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
          <!-- </v-form>
          </v-card-text> -->
        </v-card>
      </v-card>
    </v-dialog>
    <!--
    <v-dialog v-model="openDetailTable" width="100%" max-width="80%">
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">Detail Table</div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <v-card-text v-if="detailData">
            <v-data-table
              :headers="headersDetail"
              :items="detailData.items"
              :items-per-page="10"
              :sort-desc="[true]"
              multi-sort
              :footer-props="{
                'items-per-page-text': $t('rowsPerPageLabel'),
              }"
            >
              <template #[`item.option`]="{ item }">
                <v-btn fab small icon @click=";(openEditStatusTable = true), (openEditStatusTableData = item)">
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
              </template>
              <template #[`item.name`]="{ item }">
                <h5>{{ item.name }}</h5>
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip color="warning">
                  {{ item.status }}
                </v-chip>
              </template>
              &lt;!&ndash;                <template #[`item.price`]="{ item }">
                <h5>{{ currency(item.price) }}</h5>
              </template>&ndash;&gt;
              <template #[`item.qty`]="{ item }">
                <h5>{{ item.qty }}</h5>
              </template>
              <template #[`item.total_payment`]="{ item }">
                <h5>{{ currency(item.total_payment) }}</h5>
              </template>
              &lt;!&ndash;                <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-uppercase">Name</th>
                          <th class="text-center text-uppercase">Qty</th>
                          <th class="text-center text-uppercase">Total</th>
                          <th class="text-center text-uppercase">status</th>
                          &lt;!&ndash;                            <th class="text-center text-uppercase">variant</th>&ndash;&gt;
                          &lt;!&ndash;                            <th class="text-center text-uppercase">add on</th>&ndash;&gt;
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item_child in item.items" :key="i + '-id'">
                          <td>
                            <v-card width="300px">
                              <v-card-text class="name-card">
                                <p style="color: black" class="text-white">
                                  {{ item_child.name }}
                                </p>
                              </v-card-text>
                            </v-card>
                          </td>
                          <td class="text-center">
                            {{ item_child.qty }}
                          </td>
                          <td class="text-center">
                            {{ currency(item_child.total_payment) }}
                          </td>
                          <td class="text-center">
                            <v-chip color="warning">
                              {{ item_child.status }}
                            </v-chip>
                          </td>
                          &lt;!&ndash;                            <td class="text-center">
                            {{ item_child.variant }}
                          </td>
                          <td class="text-center">
                            {{ item_child.addOn }}
                          </td>&ndash;&gt;
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>&ndash;&gt;
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
-->
    <!-- <detailTable
      :open-detail-table="openDetailTable"
      :detail-data="detailData"
      :open-edit-status-table="openEditStatusTableData"
      :status="status"
      @offontable="e => (this.openDetailTable = e)"
      @offontable_edit="e => (this.openEditStatusTableData = e)"
    /> -->

    <!-- Dialog delete slug -->
    <v-dialog v-model="openDeleteSlugDialog" width="600px">
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">{{ $t('delete') }} Link</div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <!-- <v-card-title class="align-start">
            <span class="text-lg font-weight-semibold">Select Link</span>
            <v-icon>
              {{ icons.mdiLink }}
            </v-icon>
          </v-card-title> -->
          <!-- <v-card-text>
            <v-select
              v-model="selectedSlug"
              :items="currentSlug"
              item-text="slug"
              item-value="slug"
              attach
              outlined
              :prepend-inner-icon="icons.mdiLinkBoxOutline"
              dense
              label="Choose Link"
              @change="setSelectedSlug"
            />
          </v-card-text> -->
          <v-card-text>
            <h3>{{ $t('EOrdering.deleteConfirm') }}</h3>
          </v-card-text>
        </v-card>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="#ffeeee" @click="openDeleteSlugDialog = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>

          <v-btn color="success" @click="confirmDeleteSlugShop">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog delete shop -->
    <v-dialog v-model="openDeleteDialog" width="600px">
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">{{ $t('delete') }} {{ $t('EOrdering.shop') }}</div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <v-card-text>
            <!-- <pre>{{deleteShopPayload}}</pre> -->
            <span style="font-weight: bold">{{ $t('EOrdering.deleteConfirm') }}</span>
            <span>{{ $t('eform.deleteEformConfirm2') }}</span>
            <v-progress-circular
              v-if="loadingFullDelete"
              class="ms-2"
              indeterminate
              :size="24"
              color="primary"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" @click="openDeleteDialog = false">
            <span style="" class="error--text size-button-action">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="primary" @click="confirmDeleteShopFull">
            <span style="" class="size-button-action">{{ $t('EOrdering.fullDelete') }}</span>
          </v-btn>

          <v-btn color="success" @click="confirmDeleteShop">
            <span style="" class="size-button-action">OK</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Edit Order Status -->
    <!-- <v-dialog
      v-model="openEditStatusDialog"
      width="100%"
      max-width="50%"
    >
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">
            {{ $t('edit') }} Status
          </div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <v-card-text>
            <v-select
              v-model="selectedStatus"
              item-text="name"
              item-value="value"
              :items="status"
            />
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-row class="justify-end">
            <v-col
              cols="12"
              lg="3"
              md="3"
            >
              <v-btn
                block
                color="error"
                @click="closeDialogEditStatus"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="3"
            >
              <v-btn
                block
                color="success"
                @click="saveStatus"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Snackbar for copy link -->
    <v-snackbar
      v-model="showSnackbarCopiedSuccessfully"
      color="success"
      :timeout="2000"
      :top="true"
      style="z-index: 5 !important"
    >
      {{ $t('LinkGenerator.copySuccess') }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeShowSnackbarCopiedSuccessfully()">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Snackbar: Copy not supported -->
    <v-snackbar
      v-model="showSnackbarCopyNotSupported"
      color="error"
      :timeout="2000"
      :top="true"
      style="z-index: 5 !important"
    >
      {{ $t('LinkGenerator.copyUnsupported') }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeShowSnackbarCopyNotSupported()">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog Add new Link -->
    <v-dialog v-model="addEorder" width="80%">
      <add-e-order ref="addorder" :shop-data="shopData" @closeModal="e => closeModalCheck(e)" />
    </v-dialog>

    <v-dialog ref="addorder" v-model="editEorder" width="80%">
      <add-e-order :shop-data="shopData" @closeModal="e => closeModalCheck(e)" />
    </v-dialog>

    <v-dialog v-model="addBroadcastDialog" width="1000" persistent scrollable>
      <template v-slot:default="">
        <v-card elevation="0">
          <v-toolbar color="primary">
            <div class="text-h5 white--text">
              {{ $t('broadcasts.createBroadcast') }}
            </div>
            <v-spacer></v-spacer>
            <v-btn class="pa-8" icon @click="closeDialogBroadcast()">
              <v-icon x-large color="white">
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <add-broadcast
              :key="newBroadcast"
              :data-contacts-from-api="dataContactsFromApi"
              @close-dialog="closeDialogBs()"
            ></add-broadcast>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <v-snackbar v-model="showSnackbar" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
      Contact List has been deleted
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import detailTable from '@/components/order/detail_table.vue'
import eOrderingMixin from '@/mixins/e-orderingMixin'
import inboxMixin from '@/mixins/inboxMixin'
import pouchDbMixin from '@/mixins/pouchDbMixin'
import {
  mdiAccount,
  mdiAccountMultiple,
  mdiAccountQuestion,
  mdiAlert,
  mdiBroadcast,
  mdiCalendarMonth,
  mdiCancel,
  mdiCardAccountDetails,
  mdiChartBar,
  mdiChatQuestion,
  mdiCheckCircle,
  mdiChevronDoubleRight,
  mdiChevronDown,
  mdiChevronRight,
  mdiCloseCircle,
  mdiCodeTags,
  mdiCog,
  mdiContentCopy,
  mdiCounter,
  mdiCurrencyUsd,
  mdiDeleteOutline,
  mdiDomain,
  mdiExport,
  mdiEyeOutline,
  mdiGestureDoubleTap,
  mdiHeadset,
  mdiImageFilterCenterFocusWeak,
  mdiKeyboardVariant,
  mdiKeyWireless,
  mdiLink,
  mdiLinkBoxOutline,
  mdiMagnify,
  mdiMessage,
  mdiMessageArrowRight,
  mdiMessageTextOutline,
  mdiOpenInNew,
  mdiPackageDown,
  mdiPackageUp,
  mdiPencil,
  mdiPlusCircle,
  mdiPlusCircleOutline,
  mdiPoundBoxOutline,
  mdiRadioTower,
  mdiShareVariant,
  mdiShopping,
  mdiShoppingSearch,
  mdiStore,
  mdiSync,
  mdiTable,
  mdiTextBox,
  mdiTriangle,
} from '@mdi/js'
import { RealtimeClient } from '@supabase/realtime-js'

// import pouchDbMixin from '@/mixins/pouchDbMixin'
// import PreviewMessage from '../broadcast/PreviewMessage'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import AddBroadcast from '../broadcast/AddBroadcast.vue'
import AddEOrder from './create.vue'

export default {
  name: 'EShop',
  components: { AddEOrder, VueApexCharts, detailTable, AddBroadcast },
  mixins: [eOrderingMixin, inboxMixin, pouchDbMixin],

  setup() {
    return {
      icons: {
        mdiOpenInNew,
        mdiChevronDoubleRight,
        mdiShareVariant,
        mdiPlusCircleOutline,
        mdiAlert,
        mdiCardAccountDetails,
        mdiCog,
        mdiMessage,
        mdiMessageArrowRight,
        mdiAccount,
        mdiHeadset,
        mdiShopping,
        mdiShoppingSearch,
        mdiAccountQuestion,
        mdiChatQuestion,
        mdiChevronRight,
        mdiChevronDown,
        mdiCodeTags,
        mdiImageFilterCenterFocusWeak,
        mdiRadioTower,
        mdiDomain,
        mdiLink,
        mdiContentCopy,
        mdiPencil,
        mdiCheckCircle,
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiLinkBoxOutline,
        mdiAccountMultiple,
        mdiMessageTextOutline,
        mdiCounter,
        mdiPoundBoxOutline,
        mdiGestureDoubleTap,
        mdiTable,
        mdiCurrencyUsd,
        mdiStore,
        mdiPackageUp,
        mdiPackageDown,
        mdiCancel,
        mdiSync,
        mdiTextBox,
        mdiBroadcast,
        mdiCalendarMonth,
        mdiKeyWireless,
        mdiKeyboardVariant,
        mdiTriangle,
      },
    }
  },

  // mixins: [pouchDbMixin],
  data() {
    return {
      chartOptionsRevenue: {},
      chartSeriesRevenue: [],
      chartOptionsOrder: {},
      chartSeriesOrder: [],
      showDetail: true,
      detailShop: false,
      dialogChatbotSetting: false,
      chatbotSetting: {
        greetingMsg: '',
        talkToCustMsg: '',
        selectedShop: null,
        qnaMsg: '',
        instanceId: '',
        keyword: '',
        keywordActive: false,
      },
      showSnackbar: false,
      menu: false,
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10),
      ],
      selectedTableData: [],
      showShopList: false,
      totalShopRevenue: 0,
      weeklyRevenue: {
        total: '',
        status: '',
      },
      weeklyOrder: {
        total: '',
        status: '',
      },

      // broadcast
      dataContactsFromApi: null,
      newBroadcast: 1,
      addBroadcastDialog: false,
      loadingTabs: false,
      currentTab: 0,
      loadingFullDelete: false,
      currentTab2: 0,
      checkShop: false,
      addEorder: false,
      editEorder: false,
      dataReturn: null,
      openEditStatusDialog: false,
      showSnackbarCopiedSuccessfully: false,
      showSnackbarCopyNotSupported: false,
      openDeleteDialog: false,
      openDeleteSlugDialog: false,
      currentSlug: [],
      selectedSlug: '',
      newChartOptions: {
        // chart: {
        //   toolbar: {
        //     show: false,
        //   },
        // },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },

        //     --v-anchor-base: #3fc19f;
        // --v-primary-base: #3fc19f;
        // --v-secondary-base: #8a8d93;
        // --v-accent-base: #0d6efd;
        // --v-error-base: #ff4c51;
        // --v-info-base: #16b1ff;
        // --v-success-base: #56ca00;
        // --v-warning-base: #ffb400;
        colors: [
          /* $themeColors.warning,
          $themeColors.error,
          $themeColors.secondary,
          $themeColors.info,
          $themeColors.success, */
          // '#F0D9FF',
          // '#e0cffe',
          // '#BFA2DB',
          // '#b992fe',
          // '#ab7efd',
        ],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          opacity: 0.7,
          type: ['solid', 'gradient'],
        },
        xaxis: {
          // categories: labels,
        },
      },
      selectedStatus: 0,
      status: [
        {
          name: 'Pending',
          value: 0,
        },
        {
          name: 'On Progress',
          value: 1,
        },
        {
          name: 'Paid',
          value: 2,
        },
        {
          name: 'Done',
          value: 3,
        },
        {
          name: 'Cancel',
          value: 4,
        },
      ],
      itemsOrderLink: [],
      chips: 0,
      shopData: null,
      openDialogSlug: false,
      openEditSlug: false,
      indexProduct: 0,
      openCreateSlug: false,
      slugPayload: {
        slug: '',
        airtableDb: '',
        googleId: '',
        facebookPixelId: '',
        shopDb: '',
        sub_id: '',
      },
      slugPayloadEdit: {
        slug: '',
        shopDb: '',
        googleId: '',
        facebookPixelId: '',
        airtableDb: '',
        sub_id: '',
      },
      slugPayloadDelete: {
        airtableDb: '',
        slug: '',
        shopDb: '',
        sub_id: '',
      },
      statusPayloadEdit: {
        orderId: '',
        sub_id: '',
        orderStatus: 0,
      },
      deleteShopPayload: {
        sub_id: '',
        // eslint-disable-next-line no-underscore-dangle
        _id: '',
        airtable_id: '',
      },
      expanded: [],
      slugAvailability: false,
      slugAvailabilityEdit: false,
      timeout: null,
      characterValidation: {
        checkCharacter(e) {
          if (e.match(/[ ]/g)) {
            return 'Slug Name cannot have spaces, use "_" instead!'
          }
          // eslint-disable-next-line no-useless-escape
          if (e.match(/[-!@#$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g)) {
            return 'Slug Name cannot have any symbols!'
          }
          if (e.match(/[A-Z]/g)) {
            return 'Slug Name cannot have any uppercase letter!'
          }

          return true
        },
      },

      // detail
      openDetailTable: false,
      detailData: null,
      openEditStatusTable: false,
      openEditStatusTableData: null,
      itemStatistic: {
        pending: 0,
        onProgress: 0,
        completed: 0,
        canceled: 0,
        totalRevenue: 0,
        newIncome: 0,
        totalOrder: 0,
        weeklyRevenue: 0,
      },
      shopId: null,
      orderData: [],
      showPass: false,
      business_plus_url: process.env.VUE_APP_URL_EBUSSINESS,
      totalShop: 0,
      startDate: null,
      endDate: null,
    }
  },
  computed: {
    currentShop() {
      return this.$store.getters['eOrdering/getCurrentShop']
    },
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    closeModal() {
      // //console.log(this.$store.getters['eOrdering/getCloseModal'], `INI CLOSE MODAL`)

      return this.$store.getters['eOrdering/getCloseModal']
    },

    // dateRangeText() {
    //   this.loadOrderData(this.shopList[this.currentTab])
    //   //console.log('aklokloklokloklokloklokloklokloklokloklo')
    //   return this.currentTab
    // },
    passComputed() {
      if (this.showPass === false) {
        return 'password'
      }

      return 'string'
    },
    input: {
      get() {
        return this.slugPayload.slug
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.slugPayload.slug = val
          this.checkAvailability()
        }, 2000)
      },
    },
    input2: {
      get() {
        return this.slugPayloadEdit.slug
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.slugPayloadEdit.slug = val
          this.checkAvailabilityEdit()
        }, 2000)
      },
    },
    headers() {
      return [
        { text: '#', value: 'option', sortable: true },
        { text: this.$t('EOrdering.idOrder'), value: 'shop_id', sortable: true },
        { text: this.$t('EOrdering.orderType'), value: 'orderData', sortable: true },
        { text: this.$t('EOrdering.orderTime'), value: 'timestamp', sortable: true },
        { text: 'STATUS', value: 'status', sortable: true },

        // { text: 'IMG', value: 'img', sortable: true },
        // { text: 'KURIR', value: 'kurir', sortable: true },
        // { text: 'QTY', value: 'qty', sortable: true },
        // { text: 'TOTAL', value: 'total_payment', sortable: true },

        // { text: 'DATE', value: 'date', sortable: true },
        // { text: 'COSTUMER', value: 'costumer', sortable: true },
        // { text: 'TOTAL ORDER', value: 'total_order', sortable: true },
      ]
    },
    headersDetail() {
      return [
        { text: '#', value: 'option', sortable: true },

        { text: this.$t('EOrdering.name'), value: 'name', sortable: true },
        { text: 'QTY', value: 'qty', sortable: true },
        { text: 'STATUS', value: 'status', sortable: true },
        { text: 'TOTAL', value: 'total_payment', sortable: true },

        // { text: 'IMG', value: 'img', sortable: true },
        // { text: 'KURIR', value: 'kurir', sortable: true },
        // { text: 'QTY', value: 'qty', sortable: true },

        // { text: 'DATE', value: 'date', sortable: true },
        // { text: 'COSTUMER', value: 'costumer', sortable: true },
        // { text: 'TOTAL ORDER', value: 'total_order', sortable: true },
      ]
    },
    dataShopLink() {
      const initData = this.$store.getters['link/getMessageListLink']
      const retData = initData.map(el => {
        el.slug_redirect = `${el.domain}${el.slug}`

        return el
      })

      return retData
    },
    instances() {
      // const listInstance = []
      // this.$store.getters['auth/getListInstance'].value.forEach(instance => {
      //   listInstance.push({
      //     label: `${instance.label} - (${instance.phone_number})`,
      //     value: instance.phone_number,
      //   })
      // })
      // //console.log(this.$store.getters['auth/getListInstance'].value, `INI INSTANCE`)
      if (this.$store.getters['auth/getListInstance'].length !== 0) {
        return this.$store.getters['auth/getListInstance'].value
      }

      return []

      // return this.$store.getters['auth/getListInstance']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },

    // shopList() {
    //   return this.$store.getters['eOrdering/getShopList']
    // },
    shops() {
      // this.shopList.forEach(el => {
      //   if (this.totalShop !== this.shopList.length) {
      //     this.menu[`menu${this.shopList.length - 1}`] = false
      //     this.dates.push([
      //       new Date(new Date().setDate(new Date().getDate() - 10)).toISOString().substring(0, 10),
      //       new Date().toISOString().substring(0, 10),
      //     ])
      //   }
      // })

      const filteredShops = this.shopList.map(el => {
        el.data.id = el._id

        return el.data
      })

      // //console.log(this.shopList, filteredShops, '======================')

      return filteredShops
    },
    orders() {
      let startDate
      let endDate

      if (this.dates?.length === 1 || new Date(this.dates[0]).getTime() === new Date(this.dates[1]).getTime()) {
        startDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate()))
          .toISOString()
          .substring(0, 10)

        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate()))
          .toISOString()
          .substring(0, 10)
        startDate = new Date(startDate).setHours(0, 0, 0, 0)
        endDate = new Date(endDate).setHours(24, 0, 0, 0)

        // //console.log(new Date(startDate), '22222222222222222222222222222', new Date(endDate))
      } else if (new Date(this.dates[0]).getTime() > new Date(this.dates[1]).getTime() && this.dates.length > 1) {
        // //console.log('22222222222222222222222222222')
        startDate = new Date(this.dates[1]).getTime()
        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1))
          .toISOString()
          .substring(0, 10)

        // //console.log(startDate, '22222222222222222222222222222', endDate)
      } else {
        startDate = new Date(this.dates[0]).getTime()
        endDate = new Date(new Date(this.dates[1]).setDate(new Date(this.dates[1]).getDate() + 1))
          .toISOString()
          .substring(0, 10)

        // //console.log(startDate, '3333333333333333333333333333', endDate)
      }

      endDate = new Date(endDate).getTime()

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.itemStatistic = {
        pending: 0,
        onProgress: 0,
        completed: 0,
        canceled: 0,
        newIncome: 0,
        totalOrder: 0,
        weeklyRevenue: 0,
      }
      const finalOrderData = []

      const currentShop = this.orderList

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      // eslint-disable-next-line consistent-return
      currentShop.forEach(el => {
        if (el.orderData) {
          if (
            new Date(el.timestamp).getTime() <= new Date(endDate).getTime() &&
            new Date(el.timestamp).getTime() >= new Date(startDate).getTime()
          ) {
            finalOrderData.push(el)
          }
        }
      })

      // //console.log(currentShop, `INI ORDER`)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.itemStatistic.pending = finalOrderData.filter(el => el.status === 0).length
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.itemStatistic.onProgress = finalOrderData.filter(el => el.status === 1).length
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.itemStatistic.canceled = finalOrderData.filter(el => el.status === 4).length
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.itemStatistic.completed = finalOrderData.filter(el => el.status === 3).length
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      // if (this.dates[0].getTime()) {

      // }
      this.itemStatistic.totalOrder = finalOrderData.length
      finalOrderData.forEach(el => {
        el.orderData.forEach(el2 => {
          if (el2.Total && el.status === 3) {
            this.itemStatistic.weeklyRevenue += el2.Total
          }
        })
      })

      return { orders: finalOrderData, startDate, endDate }
    },
  },
  watch: {
    currentShop(newVar) {
      if (newVar !== null) {
        setTimeout(async () => {
          await this.loadOrderData2()
          const allOrders = await this.loadEorderingAllOrder({ sub_id: this.user.sub_id, shopId: this.currentShop.id })
          this.totalShopRevenue = 0

          allOrders.forEach(order => {
            order.orderData.forEach(orderDetail => {
              if (orderDetail.Total && order.status === 3) {
                this.totalShopRevenue += orderDetail.Total
              }
            })
          })
        }, 1000)
      }
    },
    async orders({ startDate, endDate }) {
      const lastWeekOrder = await this.getErodering2({
        sub_id: this.user.sub_id,
        shopId: this.currentShop.id,
        startDate: startDate - 604800000,
        endDate: endDate - 604800000,
      })

      // //console.log(lastWeekOrder, `INI LAST ORDER`)
      let totalCurrentRevenue = 0
      let lastWeekRevenue = 0

      this.orderList.forEach(el => {
        el.orderData.forEach(el2 => {
          if (el2.Total && el.status === 3) {
            totalCurrentRevenue += el2.Total
          }
        })
      })

      lastWeekOrder.forEach(el => {
        el.orderData.forEach(el2 => {
          if (el2.Total && el.status === 3) {
            lastWeekRevenue += el2.Total
          }
        })
      })

      let revenueDifference = 0

      // //console.log(lastWeekRevenue, totalCurrentRevenue, `INI BEDANY`)
      if (lastWeekRevenue === 0) {
        this.weeklyRevenue = {
          status: '+',
          total: `${(totalCurrentRevenue * 0.01).toFixed(1)}%`,
        }
      } else if (totalCurrentRevenue === 0) {
        this.weeklyRevenue = {
          status: '-',
          total: `${(lastWeekRevenue * 0.01).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue > totalCurrentRevenue) {
        revenueDifference = lastWeekRevenue - totalCurrentRevenue
        this.weeklyRevenue = {
          status: '-',
          total: `${((revenueDifference / lastWeekRevenue) * 100).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue < totalCurrentRevenue) {
        revenueDifference = totalCurrentRevenue - lastWeekRevenue
        this.weeklyRevenue = {
          status: '+',
          total: `${((revenueDifference / lastWeekRevenue) * 100).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue < totalCurrentRevenue) {
        this.weeklyRevenue = {
          status: '+',
          total: '0%',
        }
      }

      if (lastWeekOrder.length > this.orderList.length) {
        this.weeklyOrder = {
          status: '-',
          total: `${(lastWeekOrder.length - this.orderList.length) * 100}%`,
        }
      } else if (lastWeekOrder.length < this.orderList.length) {
        this.weeklyOrder = {
          status: '+',
          total: `${(this.orderList.length - lastWeekOrder.length) * 100}%`,
        }
      } else if (lastWeekOrder.length === this.orderList.length) {
        this.weeklyOrder = {
          status: '+',
          total: '0%',
        }
      }

      this.chartOptionsRevenue = {
        chart: {
          id: 'revenue-chart',

          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
          fontFamily: '"Inter", sans-serif',
        },
        colors: ['#003f5c', '#58508d'],
        labels: ['Last Week', 'This Week'],
        dataLabels: {
          enabled: false,

          // formatter(val) {
          //   // eslint-disable-next-line radix
          //   return `${val.toLocaleString('id', { style: 'currency', currency: 'IDR' })}`
          // },
        },
        stroke: {
          show: true,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      }

      this.chartSeriesRevenue = [
        {
          name: 'Revenue',
          data: [lastWeekRevenue, totalCurrentRevenue],
        },
      ]

      this.chartOptionsOrder = {
        chart: {
          id: 'order-chart',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
          fontFamily: '"Inter", sans-serif',
        },
        colors: ['#ff6361', '#ffa600'],
        labels: ['Last Week', 'This Week'],
        dataLabels: {
          enabled: false,

          // formatter(val) {
          //   // eslint-disable-next-line radix
          //   return `${val.toLocaleString('id', { style: 'currency', currency: 'IDR' })}`
          // },
        },
        stroke: {
          show: true,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      }

      this.chartSeriesOrder = [
        {
          name: 'Order',
          data: [lastWeekOrder.length, this.orderList.length],
        },
      ]
    },
    shopList(newVal) {
      // eslint-disable-next-line no-prototype-builtins
      if (newVal.length === 0) {
        this.$store.commit('eOrdering/setCurrentShop', null)
      }

      // //console.log('items', items)
      if (newVal.length !== 0) {
        this.checkShop = true
      } else {
        this.checkShop = false
      }
    },
    async closeModal(newVar) {
      // //console.log(newVar, `INI NEW VAR`)
      if (newVar) {
        await this.getShop({ sub_id: this.user.sub_id })

        // this.getDateandMenu()
        this.$store.commit('eOrdering/setCloseModal', false)
      }
    },
  },
  beforeDestroy() {
    // this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_CONTACTS)
  },
  async mounted() {
    // const test = 15
    // //console.log('currency', test % 10)
    // this.$store.commit('eOrdering/setCurrentShop', null)
    this.$store.commit('eOrdering/setCloseModal', false)

    // await this.connectEordering()
    // await this.connectContacts()
    await this.getShop({ sub_id: this.user.sub_id })

    // if (this.shopList.length && this.shopList.length !== 0) {
    //   //console.log(true, `MASUK IF 1`)
    //   this.$store.commit('eOrdering/setCurrentShop', JSON.parse(JSON.stringify(this.shopList[0].data)))
    // }

    // //console.log(this.currentShop, 'ajklsjalsjkakposjaoihnsiuahgsiuy')

    this.$store
      .dispatch('broadcast/getContacts', {
        subId: this.user.sub_id,
        token: this.user.token,
      })
      .then(response => {
        // //console.log(response, ' == response')

        // this.isLoadContactsDone = true
        this.dataContactsFromApi = response
      })
      .catch(err => {
        console.log(err, ' == error')
      })

    // this.totalShop = this.shopList.length

    // //console.log(this.shops, '-=-=-=-=-=-=-=-=-')
    // if (this.shops.length > 0) {
    //   this.shops.forEach((el, i) => {
    //     this.menu[`menu${i}`] = false
    //     this.dates[i] = [
    //       new Date(new Date().setDate(new Date().getDate() - 5)).toISOString().substring(0, 10),
    //       new Date().toISOString().substring(0, 10),
    //     ]
    //   })
    // }
    await this.getContactAll()

    // await this.$store.dispatch('eOrdering/getOrdering', { sub_id: this.user.sub_id })
    if (this.shopList.length !== 0) {
      this.checkShop = true
    }

    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_CONTACTS, async payload => {
    //   //console.log('idx_test2', payload)

    //   // this.$store.dispatch('global/setContactsById', payload)
    //   await this.loadContacts()
    //   const idx = this.allContactsGlobal.findIndex(x => x._id == payload.id)

    //   let newChat = false
    //   if (idx > -1) {
    //     if (payload.doc.last_message > this.allContactsGlobal[idx].last_message) {
    //       newChat = true
    //     }
    //     this.allContactsGlobal[idx] = payload.doc
    //   } else {
    //     newChat = true
    //     this.allContactsGlobal.push(payload.doc)
    //   }

    //   if (newChat) {
    //     await this.getOrder(payload)
    //     await this.connectEordering()
    //     await this.connectContacts()
    //     await this.getErodering()
    //   }
    // })
    this.getDateandMenu()

    if (this.shopList.length !== 0) {
      this.shopId = this.shopList[0]
      await this.loadOrderData2()
      const allOrders = await this.loadEorderingAllOrder({ sub_id: this.user.sub_id, shopId: this.currentShop.id })
      this.totalShopRevenue = 0

      allOrders.forEach(order => {
        order.orderData.forEach(orderDetail => {
          if (orderDetail.Total && order.status === 3) {
            this.totalShopRevenue += orderDetail.Total
          }
        })
      })
    }

    const REALTIME_URL = 'wss://realtime.superwa.io/socket'
    const client = new RealtimeClient(REALTIME_URL)
    client.connect()
    client.onOpen(() => console.log('Socket eordering opened.'))
    client.onClose(() => console.log('Socket eordering closed.'))
    client.onError(e => console.log('Socket eordering error', e))

    const databaseChanges = client.channel(`realtime:${this.user.sub_id}:eorderings`)
    databaseChanges.on('INSERT', e => this.updateEordering(e))
    databaseChanges.on('UPDATE', e => this.updateEordering(e))
    databaseChanges.subscribe()

    await this.runEventErodering()

    // //console.log(this.currentShop, this.shopList, 'asjaksjakjs')
  },

  methods: {
    async updateEordering(e) {
      if (this.currentShop !== null) {
        const allOrderData2 = await this.loadEorderingOrder({
          sub_id: this.user.sub_id,
          shopId: this.currentShop.id,
          startDate: this.startDate,
          endDate: this.endDate,
        })

        this.orderList = allOrderData2

        const allOrders = await this.loadEorderingAllOrder({ sub_id: this.user.sub_id, shopId: this.currentShop.id })

        this.totalShopRevenue = 0
        allOrders.forEach(order => {
          order.orderData.forEach(orderDetail => {
            if (orderDetail.Total && order.status === 3) {
              this.totalShopRevenue += orderDetail.Total
            }
          })
        })
      }
    },
    goToOrder(data) {
      this.$router.push({
        name: 'e-shop-order-list',
        params: { shopId: this.currentShop.id, tab: data },
      })

      // //console.log(true, data, 'clicked, goto order')
    },
    formatAddress(currentShop) {
      return `${currentShop.address.detail}${
        currentShop.address.city.type ? `, ${currentShop.address.city.type} ` : ''
      } ${currentShop.address.city.city_name ? `${currentShop.address.city.city_name},` : ''} ${
        currentShop.address.subdistrict.subdistrict_name
          ? `Kecamatan ${currentShop.address.subdistrict.subdistrict_name},`
          : ''
      } ${currentShop.address.city.province ? currentShop.address.city.province : ''}`
    },
    async openChatbotSetting() {
      const res = await this.$store.dispatch('eOrdering/getChatbot', { subId: this.user.sub_id })

      if (res.status !== 'failed') {
        this.chatbotSetting = res.data
      }

      this.dialogChatbotSetting = true
    },
    async saveChatbotSetting() {
      // //console.log(this.chatbotSetting, `INI HASIL`)
      const payload = JSON.parse(JSON.stringify(this.chatbotSetting))
      payload.subId = this.user.sub_id

      const res = await this.$store.dispatch('eOrdering/registerChatbot', payload)

      // //console.log(payload, res, `INI PAYLOAD`)
      this.dialogChatbotSetting = false
      this.chatbotSetting = {
        greetingMsg: '',
        talkToCustMsg: '',
        selectedShop: null,
        qnaMsg: '',
        instanceId: '',
        keyword: '',
        keywordActive: false,
      }
    },
    closeChatbotSetting() {
      this.dialogChatbotSetting = false
      this.chatbotSetting = {
        greetingMsg: '',
        talkToCustMsg: '',
        selectedShop: null,
        qnaMsg: '',
        instanceId: '',
        keyword: '',
        keywordActive: false,
      }
    },
    async editSheet() {
      // //console.log(this.currentShop, `INI SHOP DI SHEET`)

      this.$router.push({
        name: 'sheet-editor',
        params: {
          id: this.currentShop.excelId,
          driveId: this.currentShop.driveInfo.id,
          name: this.currentShop.name,
        },
      })
    },
    async bulkUpdateStatus() {
      const orderId = this.selectedTableData.map(data => data._id)
      const updatePayload = {
        sub_id: this.user.sub_id,
        orderStatus: this.selectedStatus,
        orderId,
      }

      await this.$store.dispatch('eOrdering/bulkUpdateStatus', updatePayload)
      this.selectedStatus = 0
      this.selectedTableData = []
    },
    resetOrderSelect() {
      this.selectedTableData = []
    },
    async loadOrderData2() {
      let startDate
      let endDate

      if (this.dates.length === 1) {
        startDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() - 1))
          .toISOString()
          .substring(0, 10)

        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      } else if (new Date(this.dates[0]).getTime() > new Date(this.dates[1]).getTime() && this.dates.length > 1) {
        startDate = new Date(this.dates[1]).getTime()
        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      } else {
        startDate = new Date(this.dates[0]).getTime()
        endDate = new Date(new Date(this.dates[1]).setDate(new Date(this.dates[1]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      }
      endDate = new Date(endDate).getTime()
      this.endDate = endDate
      this.startDate = startDate

      await this.getErodering({ sub_id: this.user.sub_id, shopId: this.currentShop.id, startDate, endDate })
      const lastWeekOrder = await this.getErodering2({
        sub_id: this.user.sub_id,
        shopId: this.currentShop.id,
        startDate: startDate - 604800000,
        endDate: endDate - 604800000,
      })
      let totalCurrentRevenue = 0
      let lastWeekRevenue = 0

      this.orderList.forEach(el => {
        el.orderData.forEach(el2 => {
          if (el2.Total && el.status === 3) {
            totalCurrentRevenue += el2.Total
          }
        })
      })

      lastWeekOrder.forEach(el => {
        el.orderData.forEach(el2 => {
          if (el2.Total && el.status === 3) {
            lastWeekRevenue += el2.Total
          }
        })
      })

      let revenueDifference = 0

      // //console.log(lastWeekRevenue, totalCurrentRevenue, `INI BEDANY`)
      if (lastWeekRevenue === 0) {
        this.weeklyRevenue = {
          status: '+',
          total: `${(totalCurrentRevenue * 0.01).toFixed(1)}%`,
        }
      } else if (totalCurrentRevenue === 0) {
        this.weeklyRevenue = {
          status: '-',
          total: `${(lastWeekRevenue * 0.01).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue > totalCurrentRevenue) {
        revenueDifference = lastWeekRevenue - totalCurrentRevenue
        this.weeklyRevenue = {
          status: '-',
          total: `${((revenueDifference / lastWeekRevenue) * 100).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue < totalCurrentRevenue) {
        revenueDifference = totalCurrentRevenue - lastWeekRevenue
        this.weeklyRevenue = {
          status: '+',
          total: `${((revenueDifference / lastWeekRevenue) * 100).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue < totalCurrentRevenue) {
        this.weeklyRevenue = {
          status: '+',
          total: '0%',
        }
      }

      if (lastWeekOrder.length > this.orderList.length) {
        this.weeklyOrder = {
          status: '-',
          total: `${(lastWeekOrder.length - this.orderList.length) * 100}%`,
        }
      } else if (lastWeekOrder.length < this.orderList.length) {
        this.weeklyOrder = {
          status: '+',
          total: `${(this.orderList.length - lastWeekOrder.length) * 100}%`,
        }
      } else if (lastWeekOrder.length === this.orderList.length) {
        this.weeklyOrder = {
          status: '+',
          total: '0%',
        }
      }

      this.chartOptionsRevenue = {
        chart: {
          id: 'revenue-chart',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
          fontFamily: '"Inter", sans-serif',
        },
        colors: ['#003f5c', '#58508d'],
        labels: ['Last Week', 'This Week'],
        dataLabels: {
          enabled: false,

          // formatter(val) {
          //   // eslint-disable-next-line radix
          //   return `${val.toLocaleString('id', { style: 'currency', currency: 'IDR' })}`
          // },
        },
        stroke: {
          show: true,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      }

      this.chartSeriesRevenue = [
        {
          name: 'Revenue',
          data: [lastWeekRevenue, totalCurrentRevenue],
        },
      ]

      this.chartOptionsOrder = {
        chart: {
          id: 'order-chart',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
          fontFamily: '"Inter", sans-serif',
        },
        colors: ['#ff6361', '#ffa600'],
        labels: ['Last Week', 'This Week'],
        dataLabels: {
          enabled: false,

          // formatter(val) {
          //   // eslint-disable-next-line radix
          //   return `${val.toLocaleString('id', { style: 'currency', currency: 'IDR' })}`
          // },
        },
        stroke: {
          show: true,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      }

      this.chartSeriesOrder = [
        {
          name: 'Order',
          data: [lastWeekOrder.length, this.orderList.length],
        },
      ]

      // this.chartSeriesOrder[0].data = [this.orderList.length]
      // this.chartSeriesOrder[1].data = [lastWeekOrder.length]

      // //console.log(this.chartSeriesRevenue, this.chartSeriesOrder, `INI CHART SERIES`)
    },
    async loadOrderData(shopId) {
      // //console.log(this.currentTab, 'aaaaaaaaaaaaaaa')
      let startDate
      let endDate

      if (this.dates?.length === 1) {
        startDate = new Date(
          new Date(this.dates[this.currentTab][0]).setDate(new Date(this.dates[this.currentTab][0]).getDate() - 1),
        )
          .toISOString()
          .substring(0, 10)

        endDate = new Date(
          new Date(this.dates[this.currentTab][0]).setDate(new Date(this.dates[this.currentTab][0]).getDate() + 1),
        )
          .toISOString()
          .substring(0, 10)
      } else if (
        new Date(this.dates[this.currentTab][0]).getTime() > new Date(this.dates[this.currentTab][1]).getTime() &&
        this.dates[this.currentTab].length > 1
      ) {
        startDate = new Date(this.dates[this.currentTab][1]).getTime()
        endDate = new Date(
          new Date(this.dates[this.currentTab][0]).setDate(new Date(this.dates[this.currentTab][0]).getDate() + 1),
        )
          .toISOString()
          .substring(0, 10)
      } else {
        startDate = new Date(this.dates[this.currentTab][0]).getTime()
        endDate = new Date(
          new Date(this.dates[this.currentTab][1]).setDate(new Date(this.dates[this.currentTab][1]).getDate() + 1),
        )
          .toISOString()
          .substring(0, 10)
      }
      endDate = new Date(endDate).getTime()
      this.endDate = endDate
      this.startDate = startDate

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.itemStatistic = {
      //   Quantity: 0,
      //   Status: 0,
      //   Total: 0,
      //   Done: 0,
      // }
      // const finalOrderData = []
      await this.getErodering({ sub_id: this.user.sub_id, shopId: shopId._id, startDate, endDate })

      // const currentShop = this.orderList

      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.itemStatistic.Status = currentShop.filter(el => el.status === 0).length
      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.itemStatistic.Done = currentShop.filter(el => el.status === 3).length
      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.itemStatistic.Quantity = currentShop.length
      // // eslint-disable-next-line consistent-return
      // currentShop.forEach(el => {
      //   if (el.orderData) {
      //     el.orderData.forEach(el2 => {
      //       if (el2.Total) {
      //         this.itemStatistic.Total += el2.Total
      //       }
      //     })
      //   }
      // })
      // //console.log(currentShop, 'ghiouschiusbciubsciuybciubciub')
      // //console.log(startDate, endDate, '=====+++++++++==============')
    },
    async loadOrderDataChange(shopId) {
      // //console.log(shopId, '000000000000000000')

      // //console.log('aaaaaaaaaaaaaaa')
      let startDate
      let endDate

      if (
        this.dates[this.currentTab].length === 1 ||
        new Date(this.dates[this.currentTab][0]).getTime() === new Date(this.dates[this.currentTab][1]).getTime()
      ) {
        startDate = new Date(
          new Date(this.dates[this.currentTab][0]).setDate(new Date(this.dates[this.currentTab][0]).getDate()),
        )
          .toISOString()
          .substring(0, 10)

        endDate = new Date(
          new Date(this.dates[this.currentTab][0]).setDate(new Date(this.dates[this.currentTab][0]).getDate()),
        )
          .toISOString()
          .substring(0, 10)
        startDate = new Date(startDate).setHours(0, 0, 0, 0)
        endDate = new Date(endDate).setHours(24, 0, 0, 0)

        // startDate = new Date(
        //   new Date(this.dates[this.currentTab][0]).setDate(new Date(this.dates[this.currentTab][0]).getDate() - 1),
        // )
        //   .toISOString()
        //   .substring(0, 10)

        // endDate = new Date(
        //   new Date(this.dates[this.currentTab][0]).setDate(new Date(this.dates[this.currentTab][0]).getDate() + 1),
        // )
        //   .toISOString()
        //   .substring(0, 10)
      } else if (
        new Date(this.dates[this.currentTab][0]).getTime() > new Date(this.dates[this.currentTab][1]).getTime() &&
        this.dates[this.currentTab].length > 1
      ) {
        startDate = new Date(this.dates[this.currentTab][1]).getTime()
        endDate = new Date(
          new Date(this.dates[this.currentTab][0]).setDate(new Date(this.dates[this.currentTab][0]).getDate() + 1),
        )
          .toISOString()
          .substring(0, 10)
      } else {
        startDate = new Date(this.dates[this.currentTab][0]).getTime()
        endDate = new Date(
          new Date(this.dates[this.currentTab][1]).setDate(new Date(this.dates[this.currentTab][1]).getDate() + 1),
        )
          .toISOString()
          .substring(0, 10)
      }
      endDate = new Date(endDate).getTime()
      this.endDate = endDate
      this.startDate = startDate

      await this.getErodering({ sub_id: this.user.sub_id, shopId: shopId._id, startDate, endDate })
    },
    async loadOrderDataChange2() {
      // //console.log(shopId, '000000000000000000')

      // //console.log('aaaaaaaaaaaaaaa')
      let startDate
      let endDate

      if (this.dates.length === 1 || new Date(this.dates[0]).getTime() === new Date(this.dates[1]).getTime()) {
        startDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate()))
          .toISOString()
          .substring(0, 10)

        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate()))
          .toISOString()
          .substring(0, 10)
        startDate = new Date(startDate).setHours(0, 0, 0, 0)
        endDate = new Date(endDate).setHours(24, 0, 0, 0)

        // startDate = new Date(
        //   new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() - 1),
        // )
        //   .toISOString()
        //   .substring(0, 10)

        // endDate = new Date(
        //   new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1),
        // )
        //   .toISOString()
        //   .substring(0, 10)
      } else if (new Date(this.dates[0]).getTime() > new Date(this.dates[1]).getTime() && this.dates.length > 1) {
        startDate = new Date(this.dates[1]).getTime()
        endDate = new Date(new Date(this.dates[0]).setDate(new Date(this.dates[0]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      } else {
        startDate = new Date(this.dates[0]).getTime()
        endDate = new Date(new Date(this.dates[1]).setDate(new Date(this.dates[1]).getDate() + 1))
          .toISOString()
          .substring(0, 10)
      }
      endDate = new Date(endDate).getTime()
      this.endDate = endDate
      this.startDate = startDate

      await this.getErodering({ sub_id: this.user.sub_id, shopId: this.currentShop.id, startDate, endDate })
      const lastWeekOrder = await this.getErodering2({
        sub_id: this.user.sub_id,
        shopId: this.currentShop.id,
        startDate: startDate - 604800000,
        endDate: endDate - 604800000,
      })
      let totalCurrentRevenue = 0
      let lastWeekRevenue = 0

      this.orderList.forEach(el => {
        el.orderData.forEach(el2 => {
          if (el2.Total && el.status === 3) {
            totalCurrentRevenue += el2.Total
          }
        })
      })

      lastWeekOrder.forEach(el => {
        el.orderData.forEach(el2 => {
          if (el2.Total && el.status === 3) {
            lastWeekRevenue += el2.Total
          }
        })
      })

      let revenueDifference = 0

      // //console.log(lastWeekRevenue, totalCurrentRevenue, `INI BEDANY`)
      if (lastWeekRevenue === 0) {
        this.weeklyRevenue = {
          status: '+',
          total: `${(totalCurrentRevenue * 0.01).toFixed(1)}%`,
        }
      } else if (totalCurrentRevenue === 0) {
        this.weeklyRevenue = {
          status: '-',
          total: `${(lastWeekRevenue * 0.01).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue > totalCurrentRevenue) {
        revenueDifference = lastWeekRevenue - totalCurrentRevenue
        this.weeklyRevenue = {
          status: '-',
          total: `${((revenueDifference / lastWeekRevenue) * 100).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue < totalCurrentRevenue) {
        revenueDifference = totalCurrentRevenue - lastWeekRevenue
        this.weeklyRevenue = {
          status: '+',
          total: `${((revenueDifference / lastWeekRevenue) * 100).toFixed(1)}%`,
        }
      } else if (lastWeekRevenue < totalCurrentRevenue) {
        this.weeklyRevenue = {
          status: '+',
          total: '0%',
        }
      }

      if (lastWeekOrder.length > this.orderList.length) {
        this.weeklyOrder = {
          status: '-',
          total: `${(lastWeekOrder.length - this.orderList.length) * 100}%`,
        }
      } else if (lastWeekOrder.length < this.orderList.length) {
        this.weeklyOrder = {
          status: '+',
          total: `${(this.orderList.length - lastWeekOrder.length) * 100}%`,
        }
      } else if (lastWeekOrder.length === this.orderList.length) {
        this.weeklyOrder = {
          status: '+',
          total: '0%',
        }
      }

      this.chartOptionsRevenue = {
        chart: {
          id: 'revenue-chart',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
          fontFamily: '"Inter", sans-serif',
        },
        colors: ['#003f5c', '#58508d'],
        labels: ['Last Week', 'This Week'],
        dataLabels: {
          enabled: false,

          // formatter(val) {
          //   // eslint-disable-next-line radix
          //   return `${val.toLocaleString('id', { style: 'currency', currency: 'IDR' })}`
          // },
        },
        stroke: {
          show: true,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      }

      this.chartSeriesRevenue = [
        {
          name: 'Revenue',
          data: [lastWeekRevenue, totalCurrentRevenue],
        },
      ]

      this.chartOptionsOrder = {
        chart: {
          id: 'order-chart',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '12px',
          fontFamily: '"Inter", sans-serif',
        },
        colors: ['#ff6361', '#ffa600'],
        labels: ['Last Week', 'This Week'],
        dataLabels: {
          enabled: false,

          // formatter(val) {
          //   // eslint-disable-next-line radix
          //   return `${val.toLocaleString('id', { style: 'currency', currency: 'IDR' })}`
          // },
        },
        stroke: {
          show: true,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
      }

      this.chartSeriesOrder = [
        {
          name: 'Order',
          data: [lastWeekOrder.length, this.orderList.length],
        },
      ]

      // //console.log(lastWeekOrder.length, this.orderList.length, `INI DATE`)

      // //console.log(totalCurrentRevenue, lastWeekRevenue)
    },
    async closeModalCheck(e) {
      // //console.log(e, '=====+++++++++==============')

      if (e) {
        await this.getShop({ sub_id: this.user.sub_id })

        // this.getDateandMenu()
      }
      this.addEorder = false
      this.editEorder = false
    },
    getDateandMenu() {
      // this.dates = []
      // this.shopList.forEach(el => {
      //   this.menu[`menu${this.shopList.length - 1}`] = false
      //   this.dates.push([
      //     new Date(new Date().setDate(new Date().getDate() - 5)).toISOString().substring(0, 10),
      //     new Date(new Date().setDate(new Date().getDate())).toISOString().substring(0, 10),
      //   ])
      // })
    },
    LinkBroadCast(link) {
      this.$store.dispatch('link/sendLinkToBroadcast', link)

      this.addBroadcastDialog = true
      this.newBroadcast += 1
    },
    closeDialogBroadcast() {
      this.addBroadcastDialog = false
      this.$store.dispatch('link/sendLinkToBroadcast', '')
    },
    closeDialogBs() {
      this.addBroadcastDialog = false
      this.editBroadcastDialog = false
    },
    async goToContacts(item) {
      // let stilAble = this.listContactValue.find(el => {
      //   return el.id === item.id
      // })

      // if (stilAble) {
      //   if (item.deleteable) {
      // //console.log(item, `INI ITEM`)
      const data = await this.$store.dispatch('eOrdering/getContactListCheck', { contact: item.id })
      if (data.status) {
        this.$router.push({ name: 'contacts-list-custom', params: { idlist: item.id } })
      } else {
        this.showSnackbar = true
      }

      //   } else {
      //     this.$router.push('contacts-list')
      //   }
      // } else {
      //   this.$router.push({ name: 'contacts-group-list' })
      // }
    },
    setSelectedSlug() {
      this.slugPayload.slug = this.selectedSlug
    },
    closeShowSnackbarCopyNotSupported() {
      this.showSnackbarCopyNotSupported = false
    },
    closeShowSnackbarCopiedSuccessfully() {
      this.showSnackbarCopiedSuccessfully = false
    },
    async copyURL(link) {
      try {
        if (link.includes('docs.google.com')) {
          await navigator.clipboard.writeText(link)
        } else {
          await navigator.clipboard.writeText(`${this.business_plus_url}/${link}`)
        }
        this.showSnackbarCopiedSuccessfully = true
      } catch ($e) {
        this.showSnackbarCopyNotSupported = true
      }
    },
    async confirmDeleteShop() {
      const res = await this.$store.dispatch('eOrdering/deleteOrdering', this.deleteShopPayload)
      if (res.status) {
        await this.getShop({ sub_id: this.user.sub_id })

        // this.getDateandMenu()
        this.openDeleteDialog = false
      }
    },
    async confirmDeleteShopFull() {
      this.loadingFullDelete = true
      const res = await this.$store.dispatch('eOrdering/deleteOrderingandContact', this.deleteShopPayload)

      // //console.log(this.deleteShopPayload, test, `INI RES`)

      if (res.status) {
        await this.getShop({ sub_id: this.user.sub_id })

        // this.getDateandMenu()
        this.loadingFullDelete = false
        this.openDeleteDialog = false
      }
    },
    async confirmDeleteSlugShop() {
      // //console.log(this.slugPayload, `INI PAYLOAD`)

      const result = await this.$store.dispatch('eOrdering/deleteSlugEordering', this.slugPayload)
      if (result.status) {
        await this.getShop({ sub_id: this.user.sub_id })

        // this.getDateandMenu()
        this.openDeleteSlugDialog = false
      }
    },
    async openDialogEditStatus(item) {
      this.statusPayloadEdit = {
        orderId: item._id,
        sub_id: this.user.sub_id,
        orderStatus: this.selectedStatus,
      }
      await this.$store.dispatch('eOrdering/editOrderShop', this.statusPayloadEdit)
    },
    formatTime(data) {
      return moment(data).format('DD-MM-YYYY HH:mm:ss')
    },

    // getTableOrder(shopId = null) {
    //   let total = 0
    //   let status = 0
    //   let statusDone = 0
    //   this.$store.dispatch('eOrdering/getOrdering', { sub_id: this.user.sub_id })
    //   const filterOrder = this.itemsOrderLink.filter(d => d?.orderData)
    //   const filterOrder2 = filterOrder.filter(d => d?.shop_id === shopId)
    //   filterOrder2.map(d => {
    //     //console.log('filterOrder2', d.status)
    //     if (d.status === 0) {
    //       status += 1
    //     }
    //     if (d.status === 2) {
    //       statusDone += 1
    //     }
    //     d.orderData.map(d2 => {
    //       if (d2.hasOwnProperty('Total')) {
    //         total += d2.Total
    //       }
    //     })
    //   })
    //   this.itemStatistic.Quantity = filterOrder2.length
    //   this.itemStatistic.Total = total
    //   this.itemStatistic.Status = status
    //   this.itemStatistic.Done = statusDone

    //   return filterOrder2
    // },
    async deleteSlug(item, url) {
      this.openDeleteSlugDialog = true
      // eslint-disable-next-line no-underscore-dangle
      const { excelId: airtableDb } = item
      this.currentSlug = item.urlGenerate

      // //console.log(item, `INI ITEM`)

      // const { _id: airtableDb } = this.itemsOrderLink.find(product => product.value && product.shop_id === item._id)
      this.slugPayload = {
        airtableDb,
        slug: url.slug,
        // eslint-disable-next-line no-underscore-dangle
        shopDb: item.id,
        sub_id: this.user.sub_id,
      }
    },
    editSlug(item, slug) {
      // //console.log(slug, `INI SLUG`)
      // //console.log(item, `INI ITEM`)

      this.openEditSlug = true

      // const { _id: airtableDb } = this.itemsOrderLink.find(el => el._id === item.data.excelId)
      const { excelId: airtableDb } = item

      // const { _id: airtableDb } = this.itemsOrderLink.find(product => product.value && product.shop_id === item._id)
      this.currentSlug = item.urlGenerate

      this.slugPayloadEdit = {
        slug: slug.slug,
        airtableDb,
        facebookPixelId: slug.facebookId,
        googleId: slug.googleTag,
        // eslint-disable-next-line no-underscore-dangle
        shopDb: item.id,
        sub_id: this.user.sub_id,
      }

      // //console.log(slug, 'aaaaaaaaaaaaaaaa')
    },
    async saveEditSlug() {
      // //console.log(this.slugPayloadEdit, `INI EDIT`)

      const result = await this.$store.dispatch('eOrdering/updateSlugEordering', this.slugPayloadEdit)
      if (result.status) {
        await this.getShop({ sub_id: this.user.sub_id })

        // this.getDateandMenu()
        this.closeDialogEdit()
      }
    },
    goToSlug(slug) {
      // const link = this.$router.push({ name: 'order', params: { name: slug } })
      window.open(`/shop/${slug}`, '_blank')

      // window.open(link.href, '_blank')
    },
    filterVarian(data, id) {
      if (data) {
        // //console.log('filterVarian', data.includes(id), id, data)

        return data.includes(id)
      }

      return false
    },
    airtableProducts(id, i) {
      const products = this.itemsOrderLink.filter(product => product.hasOwnProperty('value'))

      /* let filteredRecords = null
      //console.log('airtableProducts', id._id, products)
      products.map(d => {
        if (d.shop_id === id._id) {
          filteredRecords = d.value.products
        }
      }) */
      const filteredRecords = products.find(d => d.shop_id === id._id)

      return filteredRecords?.value?.products
    },

    airtableVarianAddon(product, id, varian) {
      const products = this.itemsOrderLink.filter(d => d.hasOwnProperty('value'))
      const filteredRecords = products.find(d => d.shop_id === id._id)

      // //console.log('airtableProducts-2', varian)

      return filteredRecords.value.addOnsOrVariants.find(f => f.id === varian)
    },

    closeDialog() {
      this.openCreateSlug = false
    },
    closeDialogEditStatus() {
      this.openEditStatusDialog = false
    },
    closeDialogEdit() {
      this.openEditSlug = false
      this.slugPayloadEdit = {
        slug: '',
        shopDb: '',
        facebookPixelId: '',
        googleId: '',
        airtableDb: '',
        sub_id: '',
      }
      this.selectedSlug = ''
      this.slugAvailabilityEdit = false
    },
    async checkAvailabilityEdit() {
      if (
        this.slugPayloadEdit.slug !== '' &&
        !this.slugPayloadEdit.slug.match(/[ ]/g) &&
        // eslint-disable-next-line no-useless-escape
        !this.slugPayloadEdit.slug.match(/[-!@#$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g) &&
        !this.slugPayloadEdit.slug.match(/[A-Z]/g)
      ) {
        const Availability = await this.$store.dispatch('eOrdering/checkSlugEordering', {
          slug: this.slugPayloadEdit.slug,
        })
        this.slugAvailabilityEdit = Availability.status
      } else if (
        this.slugAvailabilityEdit &&
        (this.slugPayloadEdit.slug.match(/[ ]/g) ||
          // eslint-disable-next-line no-useless-escape
          this.slugPayloadEdit.slug.match(/[-!@#$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g) ||
          this.slugPayloadEdit.slug.match(/[A-Z]/g))
      ) {
        this.slugAvailabilityEdit = false
      }
    },
    async checkAvailability() {
      if (
        this.slugPayload.slug !== '' &&
        !this.slugPayload.slug.match(/[ ]/g) &&
        // eslint-disable-next-line no-useless-escape
        !this.slugPayload.slug.match(/[-!@#$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g) &&
        !this.slugPayload.slug.match(/[A-Z]/g)
      ) {
        const Availability = await this.$store.dispatch('eOrdering/checkSlugEordering', {
          slug: this.slugPayload.slug,
        })
        this.slugAvailability = Availability.status
      } else if (
        this.slugAvailability &&
        (this.slugPayload.slug.match(/[ ]/g) ||
          // eslint-disable-next-line no-useless-escape
          this.slugPayload.slug.match(/[-!@#$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g) ||
          this.slugPayload.slug.match(/[A-Z]/g))
      ) {
        this.slugAvailability = false
      }
    },
    async saveSlug() {
      // //console.log(this.slugPayload, `INI SLUG`)

      const result = await this.$store.dispatch('eOrdering/createSlugShop', this.slugPayload)

      // //console.log(result, `INI RES`)
      if (result.status) {
        await this.getShop({ sub_id: this.user.sub_id })

        // this.getDateandMenu()
        this.openCreateSlug = false
      }
    },
    async createSlug(item) {
      this.openCreateSlug = true

      // const { _id: airtableDb } = this.itemsOrderLink.find(product => product.value && product.shop_id === item._id)
      // const { _id: airtableDb } = this.itemsOrderLink.find(el => el._id === item.data.excelId)
      const { excelId: airtableDb } = item
      const { id: shopDb } = item

      this.slugPayload = {
        slug: '',
        shopDb,
        airtableDb,
        sub_id: this.user.sub_id,
      }
    },
    editShop() {
      // //console.log(shop, `INI SHOP`)
      // //console.log('shop', shop.data, this.$refs.addorder.form)

      // this.$refs.addorder.form = shop.data
      this.shopData = JSON.parse(JSON.stringify(this.currentShop))

      // //console.log(shop, `INI SHOP`)
      this.editEorder = true
    },
    async updateProduct(dataShop) {
      const { excelId: id } = dataShop.data
      // eslint-disable-next-line no-undef
      // const { id: driveId } = this.itemsOrderLink.find(el => el._id === id).driveInfo
      const { id: driveId } = dataShop.data.driveInfo

      // eslint-disable-next-line camelcase
      const { sub_id } = this.user

      const payload = {
        id,
        driveId,
        sub_id,
      }

      this.loadingTabs = true

      await this.$store.dispatch('eOrdering/syncExcelProducts', payload)

      // if (result.status) {
      //   this.itemStatistic = {
      //     Quantity: 0,
      //     Status: 0,
      //     Total: 0,
      //     Done: 0,
      //   }
      //   this.loadingTabs = false
      // } else {
      //   this.itemStatistic = {
      //     Quantity: 0,
      //     Status: 0,
      //     Total: 0,
      //     Done: 0,
      //   }
      //   this.loadingTabs = false
      // }

      //   const products = this.itemsOrderLink.filter(product => product.hasOwnProperty('value'))
      //   const filterProductUpdate = products.find(d => d.shop_id === id._id)

      //   this.$store.dispatch('eOrdering/syncAirtableProducts', payload).then(r => {
      // this.itemStatistic = {
      //   Quantity: 0,
      //   Status: 0,
      //   Total: 0,
      //   Done: 0,
      // }
      // //console.log(payload, `INI PAYLOAD`)

      this.loadingTabs = false

      //   })
    },
    async deleteShop() {
      this.openDeleteDialog = true

      // //console.log(this.currentShop, `INI SHOP`)
      const { id: _id } = this.currentShop
      // eslint-disable-next-line camelcase
      // const { sub_id } = this.user
      const { excelId, selectedContactList } = this.currentShop
      // eslint-disable-next-line camelcase
      // const { id: driveId } = this.itemsOrderLink.find(el => el._id === excelId).driveInfo
      const { id: driveId } = this.currentShop.driveInfo
      this.deleteShopPayload = {
        // sub_id,
        // eslint-disable-next-line no-underscore-dangle
        _id,
        excelId,
        driveId,
        contactList: selectedContactList.id,
      }

      // //console.log(this.deleteShopPayload, `INI PAYLOAD`)
    },
    async openAddNewLink() {
      this.shopData = null
      this.$refs.addorder.form = {
        name: '',
        tax: 0,
        enableTax: false,
        address: {
          enableDeliveryPricing: false,
          enableDeliveryPricingInter: false,
          city: {},
          province: {},
          subdistrict: {},
          detail: '',
          availableCourier: [],
          pos: '',
          origin: {},
          availableCourierInter: [],
        },
        urlGenerate: [],
        excelId: '',
        shopType: {},
        phoneNumber: '',
        selectedContactList: {},
        pricing: {
          enableCash: true,
          enableXendit: false,
          enablePaypal: false,
          keyXendit: '',
          keyPaypal: '',
        },
        driveInfo: {
          kind: '',
          id: '',
          name: '',
          mimeType: '',
          link: '',
        },
        shopLogo: '',
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        shopLocation: null,
      }
      this.addEorder = true
    },
    currency(data, type = 'IDR', lang = 'id') {
      // //console.log(data, `INI CURRENCY`)

      return data.toLocaleString(lang, { style: 'currency', currency: type })
    },
  },
}
</script>
<style lang="scss" scoped>
.height-dialog {
  max-height: 400px;
  overflow-y: auto;
}

.card-product {
  width: 100%;
  min-height: 400px;
  border: 1px solid #c9c9c9;
  border-radius: 10px;

  div {
    img {
      object-fit: cover;
      width: 100%;
      height: 200px;
    }
  }
}
.size-button-action {
  font-size: 14px;
}

.border-card {
  border: 0.5px solid #c6c6c6;
}

.border-card-statistic {
  border: 0.5px solid #c6c6c6;
  border-radius: 10px;
}

.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}

.gamification-tree {
  top: 10%;
  right: 0;
}

.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}

.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}

.text-statistic {
}

.text-statistic-revenue {
}

.shop-logo {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.shop-header {
  color: black;
  // font-weight: 700;
}

.shop-subtitle {
  color: black !important;
  // font-weight: 300;
}

.text-statistic-title {
  font-size: 15px;
}

.hero-landing {
  margin-left: 4%;
  margin-right: 4%;
  justify-content: center;
}

.hero-subtitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.border-divider {
  border-left: 1.5px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: black;
  margin-top: 6px;
  margin-bottom: 6px;
}

@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    right: 5%;
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

.text-detail {
  font-size: 11px;
  color: black;
}
.text-detail-2 {
  font-size: 11px;
  color: black;
}
.shop-bg-dark {
  background: linear-gradient(#0d6efd, black) !important;
}
.shop-bg-light {
  background: linear-gradient(#0d6efd, white) !important;
}
.shop-name {
  margin-bottom: -12px;
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .gamification-john-pose-2 {
      right: initial;
      left: 15%;
    }

    .gamification-tree {
      right: initial;
      left: 0;
    }

    .gamification-tree-4 {
      left: 30%;
      right: initial;
    }
  }
}

.mobile-header {
  font-size: 24px;
}

@media only screen and (max-width: 578px) {
  .mobile-header {
    font-size: 18px;
  }
}

.bottom-action {
  display: none;
}

@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }

  .bottom-action {
    display: inherit;
  }

  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
  .size-button-action {
    font-size: 10px;
  }
}
@media (min-width: 1060px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

.toggleUpDown {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown.rotate {
  transform: rotate(240deg);
}

.shop-list-light {
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0.25px;
  border-style: solid;
  border-color: black;
  margin-left: 6px;
  margin-right: 6px;
  color: black;
}
.shop-list-light:hover {
  background: #e8e8eb;
}

.shop-list-dark {
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0.25px;
  border-style: solid;
  border-color: white;
  margin-left: 6px;
  margin-right: 6px;
  color: white;
}
.shop-list-dark:hover {
  background: #262627;
}

.add-shop {
  margin-left: 6px;
  margin-right: 6px;
}

.add-shop-dark:hover {
  background: #262627;
  color: black;
}

.add-shop-light:hover {
  background: #e8e8eb;
  color: white;
}

.list-shop {
  border-radius: 5px;
}

.store-Statistic {
  width: 100%;
  height: 100%;

  background-color: #c4c4c4;
}
</style>
