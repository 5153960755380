<template>
  <div>
    <v-dialog v-model="openDetailTable" width="100%" max-width="80%">
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">
            {{ $t('EOrdering.detailTable') }}
          </div>
        </v-card-title>
        <v-card-text v-if="detailData" class="mobile-header">
          <v-row>
            <v-col v-for="(itemKey, i) in itemDetailDataKeys" :key="i + 'asdas'" cols="6">
              <!-- <v-card class="border-card mx-auto">
                <v-card-text> -->
              <v-row>
                <!-- <v-col
                  cols="12"
                >
                  <v-btn
                    :color="i % 2 === 0 ? 'primary' : 'error'"
                    small
                  >
                    <v-icon
                      v-if="itemKey === 'Alamat'"
                    >
                      {{ icons.mdiReceipt }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Sub Total'">
                      {{ icons.mdiCurrencyUsd }}
                    </v-icon>
                    <v-icon v-if="itemKey.includes('Pajak')">
                      {{ icons.mdiBank }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Order Type'">
                      {{ icons.mdiTruckDelivery }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Ongkos Kirim'">
                      {{ icons.mdiTruckFast }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Kurir'">
                      {{ icons.mdiTruckCargoContainer }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Source'">
                      {{ icons.mdiSearchWeb }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Note'">
                      {{ icons.mdiStickerCheck }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Total'">
                      {{ icons.mdiCurrencyUsd }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Coupon'">
                      {{ icons.mdiLabelPercent }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Tanggal'">
                      {{ icons.mdiCalendarAccount }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Jam'">
                      {{ icons.mdiCalendarClock }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Cover'">
                      {{ icons.mdiLabelVariant }}
                    </v-icon>
                    <v-icon v-if="itemKey === 'Id Meja'">
                      {{ icons.mdiTagSearch }}
                    </v-icon>
                    <span class="ms-2">{{ itemKey }}</span>
                  </v-btn>
                </v-col> -->
                <!-- <v-col
                  class="text-center"
                  cols="12"
                >
                </v-col> -->
                <v-col class="text-center col-padding" cols="12">
                  <v-textarea
                    :readonly="true"
                    rows="1"
                    auto-grow
                    dense
                    name="input-7-4"
                    :label="itemKey.translatedKey"
                    :value="
                      Number(itemDetailData[itemKey.key])
                        ? currency(itemDetailData[itemKey.key])
                        : itemDetailData[itemKey.key]
                    "
                  ></v-textarea>
                  <!-- <h5>{{ Number(itemDetailData[itemKey]) ? currency(itemDetailData[itemKey]) : itemDetailData[itemKey] }}</h5> -->
                </v-col>
              </v-row>
              <!-- </v-card-text>
              </v-card> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card elevation="0">
          <v-card-text v-if="detailData">
            <v-data-table
              :headers="headersDetail"
              :items="dataItem"
              :items-per-page="10"
              :sort-desc="[true]"
              multi-sort
              :footer-props="{
                'items-per-page-text': $t('rowsPerPageLabel'),
              }"
            >
              <!-- <template #[`item.option`]="{ item }">
                <v-btn fab small icon @click=";(openEditStatusTable = true), (openEditStatusTableData = item)">
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
              </template> -->
              <template #[`item.TotalHarga`]="{ item }">
                <h5>{{ currency(item['Total Harga']) }}</h5>
              </template>
              <template #[`item.Harga(@)`]="{ item }">
                <h5>{{ currency(item['Harga(@)']) }}</h5>
              </template>
              <!-- <template #[`item.status`]="{ item }">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <div
                        class="d-inline-flex flex-column justify-center ms-3"
                      >
                        <v-chip
                          v-if="item.status === 0"
                          color="warning"
                        >
                          Pending
                        </v-chip>
                        <v-chip
                          v-if="item.status === 1"
                          color="info"
                        >
                          On Progress
                        </v-chip>
                        <v-chip
                          v-if="item.status === 2"
                          color="primary"
                        >
                          Done
                        </v-chip>
                        <v-chip
                          v-if="item.status === 3"
                          color="success"
                        >
                          Paid
                        </v-chip>
                      </div>
                      <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                    </div>
                  </template>
                  <div class="mx-auto my-auto">
                    <v-list dense>
                      <v-list-item-group
                        v-model="chips"
                        color="primary"
                        class="mb-2 mx-auto"
                      >
                        <v-list-item
                          v-for="(stat, index) in status"
                          :key="index"
                          @click="openDialogEditStatus(item)"
                        >
                          <v-chip
                            v-if="stat.value === 0"
                            color="warning"
                            @click="selectedStatus = 0"
                          >
                            Pending
                          </v-chip>
                          <v-chip
                            v-if="stat.value === 1"
                            color="info"
                            @click="selectedStatus = 1"
                          >
                            On Progress
                          </v-chip>
                          <v-chip
                            v-if="stat.value === 2"
                            color="primary"
                            @click="selectedStatus = 2"
                          >
                            Done
                          </v-chip>
                          <v-chip
                            v-if="stat.value === 3"
                            color="success"
                            @click="selectedStatus = 3"
                          >
                            Paid
                          </v-chip>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-menu>
              </template> -->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openEditStatusTable" width="100%" max-width="50%">
      <v-card>
        <v-card-title class="mobile-header">
          <div class="text-h5">{{ $t('edit') }} Status Table</div>
        </v-card-title>
        <v-divider />
        <v-card elevation="0">
          <v-card-text v-if="detailData">
            <v-select v-model="select" item-text="name" :items="status" />
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-row class="justify-end">
            <v-col cols="12" lg="3" md="3">
              <v-btn block color="#ffeeee" @click="close">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>
            </v-col>
            <v-col cols="12" lg="3" md="3">
              <v-btn block color="success" @click="save">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiCodeTags,
  mdiCloseCircle,
  mdiEyeOutline,
  mdiChartBar,
  mdiExport,
  mdiPlusCircle,
  mdiMagnify,
  mdiDeleteOutline,
  mdiCheckCircle,
  mdiPencil,
  mdiContentCopy,
  mdiLink,
  mdiDomain,
  mdiLinkBoxOutline,
  mdiAccountMultiple,
  mdiMessageTextOutline,
  mdiRadioTower,
  mdiImageFilterCenterFocusWeak,
  mdiCounter,
  mdiPoundBoxOutline,
  mdiGestureDoubleTap,
  mdiTable,
  mdiArrowLeft,
  mdiHome,
  mdiCurrencyUsd,
  mdiBank,
  mdiTruckDelivery,
  mdiTruckFast,
  mdiTruckCargoContainer,
  mdiOpenSourceInitiative,
  mdiSearchWeb,
  mdiStickerCheck,
  mdiReceipt,
  mdiLabelPercent,
  mdiCalendarAccount,
  mdiCalendarClock,
  mdiLabelVariant,
  mdiTagSearch,
} from '@mdi/js'
import eOrderingMixin from '@/mixins/e-orderingMixin'

export default {
  name: 'Detailtable',
  setup() {
    return {
      icons: {
        mdiLabelVariant,
        mdiTagSearch,
        mdiCalendarClock,
        mdiCalendarAccount,
        mdiLabelPercent,
        mdiReceipt,
        mdiStickerCheck,
        mdiSearchWeb,
        mdiOpenSourceInitiative,
        mdiTruckCargoContainer,
        mdiCodeTags,
        mdiImageFilterCenterFocusWeak,
        mdiRadioTower,
        mdiCurrencyUsd,
        mdiDomain,
        mdiLink,
        mdiHome,
        mdiContentCopy,
        mdiPencil,
        mdiCheckCircle,
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiLinkBoxOutline,
        mdiAccountMultiple,
        mdiMessageTextOutline,
        mdiCounter,
        mdiPoundBoxOutline,
        mdiGestureDoubleTap,
        mdiTable,
        mdiArrowLeft,
        mdiBank,
        mdiTruckDelivery,
        mdiTruckFast,
      },
    }
  },
  mixins: [eOrderingMixin],
  props: {
    openDetailTable: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: null,
    },

    /* openEditStatusTable: {
      default: false,
    }, */
    status: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      // detail
      // openDetailTable: false,
      // detailData: null,
      // openEditStatusTable: false,
      openEditStatusTableData: null,
      select: null,
      openEditStatusTable: false,
      dataItem: [],
      chips: 0,
      selectedStatus: 0,
      statusPayloadEdit: {
        orderId: '',
        sub_id: '',
        orderStatus: 0,
      },
      itemDetailData: {},
      itemDetailDataKeys: [],

      // status: [
      //   {
      //     name: 'Pending',
      //     value: 0,
      //   },
      //   {
      //     name: 'On Progress',
      //     value: 1,
      //   },

      //   {
      //     name: 'Done',
      //     value: 2,
      //   },
      //   {
      //     name: 'Paid',
      //     value: 3,
      //   },
      // ],
    }
  },
  computed: {
    input: {
      get() {
        return this.slug
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.slug = val
          this.checkAvailability()
        }, 2000)
      },
    },
    headersDetail() {
      return [
        // { text: '#', value: 'option', sortable: true },

        { text: this.$t('EOrdering.name'), value: 'name' },
        { text: this.$t('EOrdering.price'), value: 'Harga(@)', sortable: true },
        { text: 'QUANTITY', value: 'Quantity', sortable: true },

        // { text: 'STATUS', value: 'status', sortable: true },
        { text: this.$t('EOrdering.totalPrice'), value: 'TotalHarga', sortable: true },

        // { text: 'IMG', value: 'img', sortable: true },
        // { text: 'KURIR', value: 'kurir', sortable: true },
        // { text: 'QTY', value: 'qty', sortable: true },

        // { text: 'DATE', value: 'date', sortable: true },
        // { text: 'COSTUMER', value: 'costumer', sortable: true },
        // { text: 'TOTAL ORDER', value: 'total_order', sortable: true },
      ]
    },

    dataShopLink() {
      const initData = this.$store.getters['link/getMessageListLink']
      const retData = initData.map(el => {
        el.slug_redirect = `${el.domain}${el.slug}`

        return el
      })

      return retData
    },
    instances() {
      const listInstance = []
      this.$store.getters['auth/getListInstance'].forEach(instance => {
        listInstance.push({
          label: `${instance.label} - (${instance.phone_number})`,
          value: instance.phone_number,
        })
      })

      return listInstance
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    detailData(varnew = []) {
      this.dataItem = []
      this.itemDetailData = {}
      this.itemDetailDataKeys = []
      varnew.orderData.map(d => {
        if (d.hasOwnProperty('Sub Total') === false && d.hasOwnProperty('ShopID') === false) {
          this.dataItem.push(d)
        } else if (d.hasOwnProperty('Sub Total') && !d.hasOwnProperty('ShopID')) {
          this.itemDetailData = d
          if (this.itemDetailData.hasOwnProperty('Alamat')) {
            this.itemDetailData.Alamat = this.itemDetailData.Alamat.split(',').join(', ')
          }
          const keys = Object.keys(d)
          keys.forEach(key => {
            this.itemDetailDataKeys.push({
              key,
            })
          })
        }

        // this.dataItem.push(d)
      })
      this.itemDetailDataKeys.forEach(el => {
        if (el.key === 'Note') {
          el.translatedKey = this.$t('EOrdering.note')
        } else if (el.key === 'Alamat') {
          el.translatedKey = this.$t('EOrdering.address')
        } else if (el.key === 'Sub Total') {
          el.translatedKey = this.$t('EOrdering.subTotal')
        } else if (el.key.includes('Pajak')) {
          el.translatedKey = `${this.$t('EOrdering.tax')} ${el.key.replace(/[Pajak]/g, '')}`
        } else if (el.key === 'Order Type') {
          el.translatedKey = this.$t('EOrdering.orderTypeDetail')
        } else if (el.key === 'Ongkos Kirim') {
          el.translatedKey = this.$t('EOrdering.deliveryPrice')
        } else if (el.key === 'Kurir') {
          el.translatedKey = this.$t('EOrdering.courier')
        } else if (el.key === 'Source') {
          el.translatedKey = this.$t('EOrdering.source')
        } else if (el.key === 'Total') {
          el.translatedKey = this.$t('EOrdering.total')
        } else if (el.key === 'Coupon') {
          el.translatedKey = this.$t('EOrdering.coupon')
        } else if (el.key === 'Tanggal') {
          el.translatedKey = this.$t('EOrdering.date')
        } else if (el.key === 'Jam') {
          el.translatedKey = this.$t('EOrdering.time')
        } else if (el.key === 'Cover') {
          el.translatedKey = this.$t('EOrdering.cover')
        } else if (el.key === 'Id Meja') {
          el.translatedKey = this.$t('EOrdering.idTable')
        } else {
          el.translatedKey = this.$t('EOrdering.roomNo')
        }
      })

      // //console.log(this.itemDetailData, `INI DATA`)
      // //console.log(this.itemDetailDataKeys, `INI KEY`)

      // //console.log('this.dataItem', this.dataItem)
    },
    openDetailTable(varnew) {
      // //console.log('offontable', varnew)
      this.$emit('offontable', varnew)
    },
    openEditStatusTable(varnew) {
      this.$emit('offontable_edit', varnew)
    },
    openEditStatusTableData(varnew) {
      // //console.log('data_check', varnew)
      this.select = varnew?.status
    },
  },
  methods: {
    async openDialogEditStatus(item) {
      // //console.log(item.status, this.selectedStatus, true, `INI STATUS ITEM`)
      // //console.log(item, this.detailData, this.dataItem, `INI ITEM`)

      this.openEditStatusDialog = true
      this.statusPayloadEdit = {
        orderId: this.detailData._id,
        sub_id: this.user.sub_id,
        orderStatus: this.selectedStatus,
        orderData: item,
      }
      const result = await this.$store.dispatch('eOrdering/editOrderShop', this.statusPayloadEdit)

      // //console.log(result, `INI RES`)
      this.openDetailTable = false

      // //console.log(this.statusPayloadEdit, this.selectedStatus, `INI PAYLOAD EDIT STATUS`)
    },
    currency(data, type = 'IDR', lang = 'id') {
      return data.toLocaleString(lang, { style: 'currency', currency: type })
    },
    close() {
      this.openEditStatusTable = false
    },
    save() {
      if (this.select !== null && this.openEditStatusTableData !== null) {
        this.openEditStatusTableData.status = this.select
        this.openEditStatusTable = false
        this.$emit('offontable_edit', false)
      }
    },
  },
}
</script>

<style scoped>
.border-card {
  border: 0.5px solid #c6c6c6;
}

.col-padding {
  /* margin-top: -10px */
  margin-bottom: -15px;
}

.v-text-area {
  font-size: 14px;
}
</style>
