var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"100%","max-width":"80%"},model:{value:(_vm.openDetailTable),callback:function ($$v) {_vm.openDetailTable=$$v},expression:"openDetailTable"}},[_c('v-card',[_c('v-card-title',{staticClass:"mobile-header"},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('EOrdering.detailTable'))+" ")])]),(_vm.detailData)?_c('v-card-text',{staticClass:"mobile-header"},[_c('v-row',_vm._l((_vm.itemDetailDataKeys),function(itemKey,i){return _c('v-col',{key:i + 'asdas',attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-center col-padding",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":true,"rows":"1","auto-grow":"","dense":"","name":"input-7-4","label":itemKey.translatedKey,"value":Number(_vm.itemDetailData[itemKey.key])
                      ? _vm.currency(_vm.itemDetailData[itemKey.key])
                      : _vm.itemDetailData[itemKey.key]}})],1)],1)],1)}),1)],1):_vm._e(),_c('v-divider'),_c('v-card',{attrs:{"elevation":"0"}},[(_vm.detailData)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersDetail,"items":_vm.dataItem,"items-per-page":10,"sort-desc":[true],"multi-sort":"","footer-props":{
              'items-per-page-text': _vm.$t('rowsPerPageLabel'),
            }},scopedSlots:_vm._u([{key:"item.TotalHarga",fn:function(ref){
            var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(_vm.currency(item['Total Harga'])))])]}},{key:"item.Harga(@)",fn:function(ref){
            var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(_vm.currency(item['Harga(@)'])))])]}}],null,true)})],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"100%","max-width":"50%"},model:{value:(_vm.openEditStatusTable),callback:function ($$v) {_vm.openEditStatusTable=$$v},expression:"openEditStatusTable"}},[_c('v-card',[_c('v-card-title',{staticClass:"mobile-header"},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('edit'))+" Status Table")])]),_c('v-divider'),_c('v-card',{attrs:{"elevation":"0"}},[(_vm.detailData)?_c('v-card-text',[_c('v-select',{attrs:{"item-text":"name","items":_vm.status},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-row',{staticClass:"justify-end"},[_c('v-col',{attrs:{"cols":"12","lg":"3","md":"3"}},[_c('v-btn',{attrs:{"block":"","color":"#ffeeee"},on:{"click":_vm.close}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('cancel')))])])],1),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"3"}},[_c('v-btn',{attrs:{"block":"","color":"success"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }